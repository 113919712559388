import React, { useContext } from 'react';
import Button from 'src/components/Button';
import styled from 'styled-components';
import TableContext from '../contexts/TableContext';

const numberOfPagesToShowBehindAndForward = 3;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  margin: 8px 0;
`;

const Pagination: React.FC = () => {
  const { page, setPage, numberOfPages } = useContext(TableContext);

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <PaginationContainer>
      {Array(numberOfPages)
        .fill('')
        .map((_, index) => index)
        .filter((index) => {
          const minimalPage = page - numberOfPagesToShowBehindAndForward;
          const maximalPage = page + numberOfPagesToShowBehindAndForward;

          return (
            index >= minimalPage &&
            index < maximalPage &&
            index <= numberOfPages
          );
        })
        .map((index) => {
          const pageNumber = index + 1;

          return (
            <Button
              key={index}
              primary
              disabled={pageNumber === page}
              onClick={() => handleChangePage(pageNumber)}
            >
              {pageNumber}
            </Button>
          );
        })}
    </PaginationContainer>
  );
};

export default Pagination;
