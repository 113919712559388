import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Button from 'src/components/Button';
import styled from 'styled-components';

const ThumbContainer = styled.div`
  position: relative;
  width: 400px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const RemoveButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(35%, -35%);
  max-width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 16px;
  background: var(--danger);
  color: var(--white);
`;

interface ThumbnailContainerProps {
  src: string;
  removeThumb: () => void;
}

const ThumbnailContainer: React.FC<ThumbnailContainerProps> = ({
  src,
  removeThumb,
}) => {
  return (
    <ThumbContainer>
      <img src={src} alt="thumbnail" className="thumb" />
      <RemoveButton danger type="button" onClick={removeThumb}>
        <AiOutlineClose />
      </RemoveButton>
    </ThumbContainer>
  );
};

export default ThumbnailContainer;
