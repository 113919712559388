import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import { ContentsContainer, SelectedContents } from './style';
import home from 'src/assets/home.svg';
import CreateAndSearchContainer from 'src/components/AppLayout/components/CreateAndSearchContainer';
import Button from 'src/components/Button';
import Table from 'src/components/AppLayout/components/Table';
import TableAndTitleContainer from 'src/components/AppLayout/components/TableAndTitleContainer';
import Content from 'src/models/content';
import {
  getContents as getContentsService,
  toggleContentActive as toggleContentActiveService,
  deleteContent as deleteContentService,
  toggleContentHighlight as toggleContentHighlightService,
} from 'src/services/contents';
import useLoading from 'src/hooks/useLoading';
import TableThumbnail from 'src/components/AppLayout/components/TableThumbnail';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineMinus,
  AiOutlinePlayCircle,
  AiOutlinePlus,
} from 'react-icons/ai';
import useModal from 'src/hooks/useModal';
import ViewVideo from 'src/components/AppLayout/components/ViewVideo';
import ActionButtonsContainer from 'src/components/AppLayout/components/ActionButtonsContainer';
import { BsTrashFill } from 'react-icons/bs';
import makeQuestion from 'src/helpers/make-question';
import showErrorMessage from 'src/helpers/show-error-message';
import showSuccessMessage from 'src/helpers/show-success-message';
import clamp from 'clamp-js';
import ThemeContext from 'src/contexts/ThemeCreationContext';
import SystemError from 'src/models/error';
import CreateAndEditContents from '../CreateAndEditContents';
import AppContext from 'src/contexts/AppContext';
import Switch from 'react-switch';

interface ContentsProps {
  modalId?: string;
}

const Contents: React.FC<ContentsProps> = ({ modalId }) => {
  const history = useHistory();
  const { showModal } = useModal();
  const { setIsLoading } = useLoading();
  const [contents, setContents] = useState([] as Content[]);
  const selectedContentsContainer = useRef<HTMLSpanElement>(null);
  const {
    selectedContents: themeSelectedContents,
    setSelectedContents: setThemeSelectedContents,
  } = useContext(ThemeContext);
  const [selectedContents, setSelectedContents] = useState([] as Content[]);
  const { selectedPartner } = useContext(AppContext);

  const { hideModal } = useModal();

  const getContents = useCallback(async () => {
    setIsLoading(true);

    const filters = {} as any;
    if (selectedPartner?.segmentation_item_id) {
      filters.segmentation_item_ids = [selectedPartner.segmentation_item_id];
    }
    if (modalId) {
      filters.is_active = true;
    }

    const { data: contents } = await getContentsService(filters);
    setContents(contents);

    setIsLoading(false);
  }, [modalId, selectedPartner?.segmentation_item_id, setIsLoading]);

  const showVideo = useCallback(
    (reference: string) => {
      showModal('Visualizar vídeo', <ViewVideo reference={reference} />);
    },
    [showModal]
  );

  const createContent = () => {
    if (modalId) {
      showModal(
        'Criar conteúdo',
        <CreateAndEditContents onUpdateOrSave={getContents} />
      );
    } else {
      history.push('/customization/academic/contents/create-content');
    }
  };

  const editContent = useCallback(
    (contentId: string) => {
      if (modalId) {
        showModal(
          'Editar conteúdo',
          <CreateAndEditContents
            contentId={contentId}
            onUpdateOrSave={getContents}
          />
        );
      } else {
        history.push(
          `/customization/academic/contents/edit-content/${contentId}`
        );
      }
    },
    [history, getContents, modalId, showModal]
  );

  const removeContent = useCallback(
    async (contentId: string) => {
      const result = await makeQuestion('Deseja excluir este conteúdo?');

      if (result.isConfirmed) {
        try {
          await deleteContentService(contentId);

          showSuccessMessage('Conteúdo removido com sucesso');

          await getContents();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getContents]
  );

  const toggleContentActive = useCallback(
    async (contentId: string, isActive: boolean) => {
      const result = await makeQuestion(
        `Deseja ${isActive ? 'inativar' : 'ativar'} este conteúdo?`
      );

      if (result.isConfirmed) {
        try {
          const shouldActive = !isActive;
          await toggleContentActiveService(contentId, shouldActive);

          showSuccessMessage(
            `Conteúdo ${shouldActive ? 'ativado' : 'inativado'} com sucesso`
          );

          await getContents();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getContents]
  );

  const toggleHighlight = useCallback(
    async (contentId: string, isHighlight: boolean) => {
      const result = await makeQuestion(
        `Tem certeza que deseja ${
          isHighlight ? 'remover o destaque d' : 'destacar '
        }este conteúdo`
      );
      if (result.isConfirmed) {
        try {
          await toggleContentHighlightService(contentId);

          showSuccessMessage(
            `${
              isHighlight
                ? 'Destaque do conteúdo removido'
                : 'Conteúdo destacado'
            } com sucesso!`
          );

          await getContents();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getContents]
  );

  const isSelectedContent = useCallback(
    (content: Content) => {
      return (selectedContents || [])
        .map((c) => c.content_id)
        .includes(content.content_id);
    },
    [selectedContents]
  );

  const selectContent = useCallback(
    (content: Content) => {
      setSelectedContents((contents) => [...contents, content]);
    },
    [setSelectedContents]
  );

  const unselectContent = useCallback(
    (content: Content) => {
      setSelectedContents((contents) =>
        contents.filter((c) => c.content_id !== content.content_id)
      );
    },
    [setSelectedContents]
  );

  const applySelectedContents = () => {
    setThemeSelectedContents(selectedContents);
    hideModal(modalId);
  };

  const contentsToShow = useMemo(() => {
    return (contents || []).map((content) => ({
      ...content,
      thumbnail: content?.images?.thumbnail ? (
        <TableThumbnail src={content?.images?.thumbnail} />
      ) : (
        <></>
      ),
      rowColor: !modalId
        ? 'auto'
        : isSelectedContent(content)
        ? 'var(--primary-40)'
        : 'transparent',
      highlight: (
        <Switch
          onChange={() =>
            toggleHighlight(content.content_id, content.is_highlight)
          }
          checked={content.is_highlight}
        />
      ),
      actions: (
        <ActionButtonsContainer>
          {!modalId ? (
            <>
              <Button
                primary
                type="button"
                onClick={() => editContent(content.content_id)}
              >
                <AiOutlineEdit />
                <span>Editar</span>
              </Button>
              <Button
                warning
                type="button"
                onClick={() =>
                  toggleContentActive(content.content_id, content.is_active)
                }
              >
                {content.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}{' '}
                {content.is_active ? 'Inativar' : 'Ativar'}
              </Button>
              <Button
                dangerFill
                type="button"
                onClick={() => removeContent(content.content_id)}
              >
                <BsTrashFill />
                <span>Excluir</span>
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                onClick={() =>
                  isSelectedContent(content)
                    ? unselectContent(content)
                    : selectContent(content)
                }
              >
                {isSelectedContent(content) ? (
                  <>
                    <AiOutlineMinus />
                    <span>Remover</span>
                  </>
                ) : (
                  <>
                    <AiOutlinePlus />
                    <span>Adicionar</span>
                  </>
                )}
              </Button>
            </>
          )}
        </ActionButtonsContainer>
      ),
      video: (
        <Button
          circle
          primary
          type="button"
          onClick={() => showVideo(content.reference)}
        >
          <AiOutlinePlayCircle size={32} />
        </Button>
      ),
    }));
  }, [
    contents,
    editContent,
    isSelectedContent,
    modalId,
    removeContent,
    selectContent,
    showVideo,
    toggleContentActive,
    toggleHighlight,
    unselectContent,
  ]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  useEffect(() => {
    if (selectedContentsContainer.current) {
      clamp(selectedContentsContainer.current, { clamp: 1 });
    }
  }, [selectedContentsContainer]);

  useEffect(() => {
    if (themeSelectedContents?.length) {
      setSelectedContents(themeSelectedContents);
    }
  }, [themeSelectedContents]);

  return (
    <ContentsContainer isOnModal={!!modalId}>
      {!modalId ? (
        <TitleAndBreadcrumbContainer>
          <PageTitle>Conteúdos</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/academic">Acadêmico</Link>,
              <span>Conteúdos</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      ) : (
        <></>
      )}

      <CreateAndSearchContainer>
        <Button type="button" success onClick={createContent}>
          Criar novo conteúdo
        </Button>
      </CreateAndSearchContainer>

      {modalId ? (
        <SelectedContents>
          {selectedContents?.length ? (
            <>
              <strong>Conteúdos selecionados:</strong>
              <span
                ref={selectedContentsContainer}
                className="selected-contents"
              >
                {(selectedContents || []).map((c) => c.name).join(', ')}
              </span>
            </>
          ) : (
            <span></span>
          )}
          <Button primary type="button" onClick={applySelectedContents}>
            Aplicar seleção
          </Button>
        </SelectedContents>
      ) : (
        <></>
      )}

      <TableAndTitleContainer>
        <PageTitle dark medium>
          Conteúdos
        </PageTitle>
        <Table
          headerConfigs={[
            {
              propName: 'thumbnail',
              label: 'Thumbnail',
              attributes: { style: { width: '200px' } },
            },
            { propName: 'name', label: 'Nome' },
            { propName: 'description', label: 'Descrição' },
            {
              propName: 'video',
              label: 'Vídeo',
              attributes: { style: { width: '100px' } },
            },
            {
              label: 'Destaque',
              propName: 'highlight',
              attributes: { style: { width: '100px' } },
            },
            {
              propName: 'actions',
              label: 'Ações',
              attributes: { style: { width: !modalId ? '340px' : '140px' } },
            },
          ]}
          items={contentsToShow}
        />
      </TableAndTitleContainer>
    </ContentsContainer>
  );
};

export default Contents;
