import React, { useMemo } from 'react';
import { BsFillPlayFill, BsMusicNoteBeamed } from 'react-icons/bs';
import convertSecondsToTimeText from 'src/helpers/seconds-to-time-text';
import Media from 'src/models/media';
import { ReturnedMedia } from '../..';
import { MediaCardContainer, MediaThumb } from './style';

interface MediaCardProps {
  media: Media;
  onSelectContent: (media: Partial<ReturnedMedia>) => void;
}

const MediaCard: React.FC<MediaCardProps> = ({ media, onSelectContent }) => {
  const selectContent = () => {
    if (media) {
      if (media.files && media.files.length) {
        const firstFile = media.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const reference = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${media.id}`;
          const duration = firstFile.fileInfo.duration / 1000;
          const type = firstFile.qualifier;

          onSelectContent({ url: reference, duration, type });
        }
      }
    }
  };

  const maxSizedThumbUrl = useMemo(() => {
    return media && media.thumbs && media.thumbs.length
      ? media.thumbs.sort((thumbA, thumbB) =>
          thumbA.width > thumbB.width ? -1 : 1
        )[0].url
      : '';
  }, [media]);

  const maxSizedFile = useMemo(() => {
    return (
      media &&
      media.files &&
      media.files.length &&
      media.files.sort((thumbA, thumbB) =>
        thumbA.fileSize > thumbB.fileSize ? -1 : 1
      )[0]
    );
  }, [media]);

  return (
    <MediaCardContainer onClick={() => selectContent()}>
      {maxSizedThumbUrl ? (
        <MediaThumb src={maxSizedThumbUrl} />
      ) : maxSizedFile && maxSizedFile.qualifier === 'VIDEO' ? (
        <BsFillPlayFill size={32} />
      ) : (
        <BsMusicNoteBeamed size={32} />
      )}
      <p className="title">{media.title}</p>
      <p className="duration">
        {maxSizedFile &&
          maxSizedFile.fileInfo &&
          convertSecondsToTimeText(maxSizedFile.fileInfo.duration / 1000)}
      </p>
    </MediaCardContainer>
  );
};

export default MediaCard;
