import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useLoading from 'src/hooks/useLoading';
import Partner from 'src/models/partner';
import {
  SelectPartnerContainer,
  PartnersList,
  PartnerCardContainer,
} from './style';
import {
  getPartners as getPartnersService,
  getPartner as getPartnerService,
} from 'src/services/partners';
import AppContext from 'src/contexts/AppContext';
import useModal from 'src/hooks/useModal';
import { useHistory } from 'react-router';

interface PartnerCardProps {
  partner: Partial<Partner>;
  modalId?: string;
}

const PartnerCard: React.FC<PartnerCardProps> = ({ partner, modalId }) => {
  const { setSelectedPartner, selectedPartner } = useContext(AppContext);
  const { hideModal } = useModal();
  const history = useHistory();

  const selectPartner = async () => {
    if (partner.partner_id) {
      const completePartner = await getPartnerService(partner.partner_id);
      setSelectedPartner(completePartner);
    } else {
      setSelectedPartner(undefined);
    }

    history.push('/home');
    hideModal(modalId);
  };

  const isSelectedPartner = useMemo(() => {
    return (
      !!selectedPartner && selectedPartner.partner_id === partner.partner_id
    );
  }, [partner.partner_id, selectedPartner]);

  return (
    <PartnerCardContainer selected={isSelectedPartner} onClick={selectPartner}>
      {partner.name}
    </PartnerCardContainer>
  );
};

const SelectPartner: React.FC = () => {
  const [partners, setPartners] = useState([] as Partner[]);
  const { setIsLoading } = useLoading();

  const getPartners = useCallback(async () => {
    setIsLoading(true);

    const partners = await getPartnersService();
    setPartners(partners);

    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  return (
    <SelectPartnerContainer>
      <PartnersList>
        <PartnerCard partner={{ name: 'Manole' }} />
        {!!partners?.length &&
          partners.map((p) => <PartnerCard key={p.partner_id} partner={p} />)}
      </PartnersList>
    </SelectPartnerContainer>
  );
};

export default SelectPartner;
