import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const MenusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`;

const MenuContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  text-decoration: none;
  transition: all 0.5s;
  padding: 8px;
  border-radius: 4px;

  & .title {
    flex: 1;
    color: var(--black-60);
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
  }

  :hover {
    background: var(--opacity-gray);
  }
`;

interface MenusDividerProps {
  opened: boolean;
}

const MenusDivider = styled.h4<MenusDividerProps>`
  font-size: 0.9rem;
  color: var(--gray);
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 8px;
  border-top: solid 1px var(--gray);
  width: 100%;
  text-transform: uppercase;

  ${(props) =>
    !props.opened &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
`;

const MenuChildren = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
`;

export { MenusContainer, MenuContainer, MenusDivider, MenuChildren };
