import httpClient from 'src/http-client';
import ContentsFinishedFilters from 'src/models/contents-finished-filters';
import EngagementFilters from 'src/models/engagement-filters';

const getEngagementReport = async (type: string, params: EngagementFilters) => {
  return (
    await httpClient.get(`/reports/engagement/${type}`, {
      params,
      responseType: 'blob',
    })
  ).data;
};

const getContentsFinishedReport = async (
  type: string,
  params: ContentsFinishedFilters
) => {
  return (
    await httpClient.get(`/reports/content-finished/${type}`, {
      params,
      responseType: 'blob',
    })
  ).data;
};

export { getEngagementReport, getContentsFinishedReport };
