import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  height: 40px;
  border: none;
  outline: none;
  width: 100%;
  background: none;
  cursor: pointer;
`;

interface ColorInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const ColorInput: React.FC<ColorInputProps> = (props) => {
  return <Input type="color" {...props} />;
};

export default ColorInput;
