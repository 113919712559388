import httpClient from 'src/http-client';
import Theme from 'src/models/theme';
import Paginated from 'src/models/paginated';

const getThemes = async (filters?: any) => {
  return (
    await httpClient.get<Paginated<Theme>>(`/contents`, {
      params: { ...filters, flag: ['theme'], relations: ['segmentations'] },
    })
  ).data;
};

const getTheme = async (themeId: string) => {
  return (
    await httpClient.get<Theme>(`/contents/${themeId}`, {
      params: { relations: ['children'] },
    })
  ).data;
};

const createTheme = async (newTheme: Partial<Theme>) => {
  return (await httpClient.post(`/contents`, { ...newTheme, flag: 'theme' }))
    .data;
};

const updateTheme = async (themeId: string, newTheme: Partial<Theme>) => {
  delete newTheme.content_id;
  delete newTheme.created_at;
  delete newTheme.updated_at;
  delete newTheme.segmentation_items;

  return (
    await httpClient.put(`/contents/${themeId}`, {
      ...newTheme,
      flag: 'theme',
    })
  ).data;
};

const deleteTheme = async (themeId: string) => {
  await httpClient.delete(`/contents/${themeId}`);
};

const toggleThemeActive = async (themeId: string, shouldActive: boolean) => {
  return (
    await httpClient.patch(`/contents/${themeId}/active`, {
      is_active: shouldActive,
    })
  ).data;
};

export {
  getThemes,
  getTheme,
  createTheme,
  updateTheme,
  deleteTheme,
  toggleThemeActive,
};
