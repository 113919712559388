import httpClient from 'src/http-client';
import User from 'src/models/user';

const getUser = async () => {
  return (await httpClient.get(`/users/me`)).data;
};

const getUsers = async (params?: any) => {
  return ((await httpClient.get<User[]>(`/users`)).data || []).sort(
    (u1, u2) => {
      const u1CreationDate = u1.created_at
        ? new Date(u1.created_at)
        : new Date();
      const u2CreationDate = u2.created_at
        ? new Date(u2.created_at)
        : new Date();

      return u1CreationDate > u2CreationDate ? 1 : -1;
    }
  );
};

const toggleUserAdmin = async (userId: string) => {
  return await (
    await httpClient.patch(`/users/${userId}/admin`)
  ).data;
};

export { getUser, getUsers, toggleUserAdmin };
