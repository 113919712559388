import styled from 'styled-components';

const UploadProgressBarContainer = styled.div`
  height: 24px;
  border-radius: 4px;
  width: 120px;
  border: solid 1px var(--border-gray);
  overflow: hidden;
`;

const Progress = styled.div`
  background: var(--primary);
  height: 100%;
`;

export { UploadProgressBarContainer, Progress };
