import { createContext } from 'react';
import SignInCredentials from '../models/sign-in-credentials';
import User from '../models/user';

interface IAuthContext {
  user?: User;
  isAuthenticated: boolean;
  signIn: (signInCredentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
}

const AuthContext = createContext({} as IAuthContext);

export default AuthContext;
