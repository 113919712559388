import styled, { css } from 'styled-components';

interface ContentsContainerProps {
  isOnModal: boolean;
}

const ContentsContainer = styled.div<ContentsContainerProps>`
  flex: 1;
  width: 100%;

  ${(props) =>
    props.isOnModal &&
    css`
      width: 80vw;
      height: 75vh;
      overflow-y: auto;
    `}
`;

const SelectedContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  & .selected-contents {
    flex: 1;
    max-width: 100%;
  }
`;

export { ContentsContainer, SelectedContents };
