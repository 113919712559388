import { createContext, Dispatch, SetStateAction } from 'react';
import Partner from 'src/models/partner';

interface IAppContext {
  showUserContextMenu: boolean;
  setShowUserContextMenu: Dispatch<SetStateAction<boolean>>;
  selectedPartner: Partner | undefined;
  setSelectedPartner: Dispatch<SetStateAction<Partner | undefined>>;
}

const AppContext = createContext({} as IAppContext);

export default AppContext;
