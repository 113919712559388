import Button from 'src/components/Button';
import styled, { css } from 'styled-components';

const SelectPartnerContainer = styled.div`
  width: 80vw;
  height: 70vh;
  padding: 16px;
`;

const PartnersList = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

interface PartnerCardContainerProps {
  selected: boolean;
}

const PartnerCardContainer = styled(Button)<PartnerCardContainerProps>`
  border: solid 1px var(--primary);
  padding: 16px;
  width: 200px;

  :hover {
    background: var(--primary);
    color: var(--white);
  }

  ${(props) =>
    props.selected &&
    css`
      background: var(--primary);
      color: var(--white);
    `}
`;

export { SelectPartnerContainer, PartnersList, PartnerCardContainer };
