import styled, { css } from 'styled-components';

interface LoadingProps {
  small?: boolean;
  medium?: boolean;
}

const LoadingContainer = styled.div<LoadingProps>`
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  & .spinner {
    animation: spin 2s linear infinite;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--primary);
    width: 120px;
    height: 120px;
  }

  ${(props) =>
    props.small &&
    css`
      height: 24px;
      & .spinner {
        width: 16px;
        height: 16px;
        border-width: 1px;
      }
    `}

  ${(props) =>
    props.medium &&
    css`
      height: 64px;
      & .spinner {
        width: 48px;
        height: 48px;
        border-width: 4px;
      }
    `}
`;

const MinorLoading: React.FC<LoadingProps> = (props) => {
  return (
    <LoadingContainer {...props}>
      <div className="spinner"></div>
    </LoadingContainer>
  );
};

export default MinorLoading;
