import React, { useContext } from 'react';
import Menus from './components/menus';
import {
  AppLayoutSidebarContainer,
  CustomerLogo,
  LogoAndToggleContainer,
  SidebarToggleButton,
} from './style';
import customerLogo from 'src/assets/customer-logo-color.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import SidebarContext from 'src/contexts/SidebarContext';

const AppLayoutSidebar: React.FC = () => {
  const { opened, setOpened } = useContext(SidebarContext);

  const toggleSidebar = () => {
    setOpened(!opened);
  };

  return (
    <AppLayoutSidebarContainer opened={opened}>
      <LogoAndToggleContainer>
        {opened && <CustomerLogo src={customerLogo} />}
        <SidebarToggleButton type="button" onClick={toggleSidebar}>
          <GiHamburgerMenu size={20} color="var(--black-60)" />
        </SidebarToggleButton>
      </LogoAndToggleContainer>

      <Menus />
    </AppLayoutSidebarContainer>
  );
};

export default AppLayoutSidebar;
