import styled from 'styled-components';

const CreateAndEditSpecialtiesContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const ThemeCardContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  position: relative;
  border-top: solid 1px var(--border-gray);
  border-bottom: solid 1px var(--border-gray);
  padding: 8px 0;

  & .thumbnail {
    width: 160px;
  }

  & .name {
    flex: 1;
  }
`;

const ThemeCardButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-40%);
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
`;

const SelectedThemes = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export {
  CreateAndEditSpecialtiesContainer,
  SelectedThemes,
  ThemeCardContainer,
  ThemeCardButtonsContainer,
};
