import styled from 'styled-components';

const TextArea = styled.textarea`
  padding: 12px 4px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  box-shadow: 0px 1px 3px 0px var(--gray);
  width: 100%;
  border-radius: 4px;
  resize: vertical;
  color: var(--gray);
  font-family: 'Open Sans', sans-serif;

  ::placeholder {
    font-size: 0.9em;
  }
`;

export default TextArea;
