import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import { CreateAndEditPartnersContainer } from './style';
import home from 'src/assets/home.svg';
import {
  CreationForm,
  CreationFormButtonGroup,
  CreationFormGroup,
  CreationFormImageGroup,
} from 'src/components/AppLayout/components/Forms';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import TitleAndPartnerContainer from 'src/components/AppLayout/components/TableAndTitleContainer';
import showErrorMessage from 'src/helpers/show-error-message';
import Partner from 'src/models/partner';
import checkEmptyString from 'src/helpers/check-empty-string';
import SystemError from 'src/models/error';
import showSuccessMessage from 'src/helpers/show-success-message';
import {
  createPartner as createPartnerService,
  updatePartner as updatePartnerService,
  getPartner as getPartnerService,
} from 'src/services/partners';
import useLoading from 'src/hooks/useLoading';
import useModal from 'src/hooks/useModal';
import ColorInput from 'src/components/ColorInput';
import ThumbnailContainer from 'src/components/AppLayout/components/ThumbnailContainer';
import CutImage from 'src/components/AppLayout/components/CutImage';
import { uploadFile } from 'src/services/files';
import mylist from 'src/assets/banners/mylist.png';
import myprogress from 'src/assets/banners/myprogress.png';
import search from 'src/assets/banners/search.png';
import specialties from 'src/assets/banners/specialties.png';
import myprofile from 'src/assets/banners/myprofile.png';

interface CreateAndEditPartnersParams {
  partnerId?: string;
}

const CreateAndEditPartners: React.FC = () => {
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { showModal } = useModal();
  const { partnerId } = useParams<CreateAndEditPartnersParams>();
  const [partner, setPartner] = useState({} as Partner);

  const handleChangePartner = (propName: string, value: any) => {
    setPartner((partner) => {
      return { ...partner, [propName]: value };
    });
  };

  const validate = () => {
    if (checkEmptyString(partner.name)) {
      throw new Error('Informe um nome para o parceiro');
    }

    if (checkEmptyString(partner.url_complement)) {
      throw new Error('Informe um complemento de URL para o parceiro');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      if (isEditing) {
        await updatePartnerService(partnerId!, partner);
        showSuccessMessage('Parceiro editado com sucesso!');
      } else {
        await createPartnerService(partner);
        showSuccessMessage('Parceiro cadastrado com sucesso!');
      }

      goToPartners();
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  };

  const getPartner = useCallback(async () => {
    const defaultBanners = {
      mylist,
      myprogress,
      search,
      specialties,
      myprofile,
    };

    if (partnerId) {
      setIsLoading(true);

      const partner = await getPartnerService(partnerId);
      const partnerBanners = { ...defaultBanners, ...partner.banner };
      setPartner({ ...partner, banner: partnerBanners });

      setIsLoading(false);
    } else {
      setPartner((partner) => ({
        ...(partner || {}),
        banner: defaultBanners,
      }));
    }
  }, [partnerId, setIsLoading]);

  const goToPartners = () => {
    history.push('/customization/partners');
  };

  const cutImage = (type: string) => {
    showModal(
      'Cortar imagem',
      <CutImage
        onCutImage={(file) => uploadAndSelectImage(file, type)}
        aspect={1440 / 425}
      />
    );
  };

  const uploadAndSelectImage = async (image: File, type: string) => {
    setIsLoading(true);

    try {
      const { reference } = await uploadFile(image);
      handleChangePartner('banner', {
        ...partner.banner,
        [type]: reference,
      });
    } catch (e) {
      showErrorMessage(e as SystemError);
    }

    setIsLoading(false);
  };

  const removeBanner = (type: string) => {
    setPartner((partner) => ({
      ...(partner || {}),
      banner: { ...partner.banner, [type]: '' },
    }));
  };

  const isEditing = useMemo(() => {
    return !!partnerId;
  }, [partnerId]);

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  return (
    <CreateAndEditPartnersContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>{isEditing ? 'Editar' : 'Criar'} Parceiro</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Personalização</strong>,
            <Link to="/customization/partners">Parceiros</Link>,
            <span>{isEditing ? 'Editar' : 'Criar'} Parceiros</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <TitleAndPartnerContainer>
        <PageTitle dark medium>
          {isEditing ? 'Editar' : 'Criar'} Parceiro
        </PageTitle>

        <CreationForm onSubmit={handleSubmit}>
          <CreationFormGroup>
            <label htmlFor="name" className="required">
              Nome
            </label>
            <Input
              id="name"
              placeholder="Nome"
              value={partner.name}
              onChange={(e) => handleChangePartner('name', e.target.value)}
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="url_complement" className="required">
              URL Complementar
            </label>
            <Input
              id="url_complement"
              placeholder="URL Complementar"
              value={partner.url_complement}
              onChange={(e) =>
                handleChangePartner(
                  'url_complement',
                  `${e.target.value}`.trim().replace(/\s/, '')
                )
              }
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="primary" className="required">
              Cor Primária
            </label>

            <ColorInput
              value={partner?.color?.primary}
              onChange={(e) =>
                handleChangePartner('color', {
                  ...partner.color,
                  primary: e.target.value,
                })
              }
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="secondary" className="required">
              Cor Secundária
            </label>

            <ColorInput
              value={partner?.color?.secondary}
              onChange={(e) =>
                handleChangePartner('color', {
                  ...partner.color,
                  secondary: e.target.value,
                })
              }
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="specialties" className="required">
              Banner Especialidades (Caso não seja selecionada, será incluída a
              imagem padrão)
            </label>

            <CreationFormImageGroup>
              {partner?.banner?.specialties ? (
                <ThumbnailContainer
                  src={partner?.banner?.specialties}
                  removeThumb={() => removeBanner('specialties')}
                />
              ) : (
                <Button
                  type="button"
                  id="specialties"
                  onClick={() => cutImage('specialties')}
                >
                  Selecione a imagem para o Banner de Especialidades
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="mylist" className="required">
              Banner Minha Lista (Caso não seja selecionada, será incluída a
              imagem padrão)
            </label>

            <CreationFormImageGroup>
              {partner?.banner?.mylist ? (
                <ThumbnailContainer
                  src={partner?.banner?.mylist}
                  removeThumb={() => removeBanner('mylist')}
                />
              ) : (
                <Button
                  type="button"
                  id="mylist"
                  onClick={() => cutImage('mylist')}
                >
                  Selecione a imagem para o Banner da página "Minha Lista"
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="myprogress" className="required">
              Banner Meu Progresso (Caso não seja selecionada, será incluída a
              imagem padrão)
            </label>

            <CreationFormImageGroup>
              {partner?.banner?.myprogress ? (
                <ThumbnailContainer
                  src={partner?.banner?.myprogress}
                  removeThumb={() => removeBanner('myprogress')}
                />
              ) : (
                <Button
                  type="button"
                  id="myprogress"
                  onClick={() => cutImage('myprogress')}
                >
                  Selecione a imagem para o Banner da página "Meu Progresso"
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="myprofile" className="required">
              Banner Meu Perfil (Caso não seja selecionada, será incluída a
              imagem padrão)
            </label>

            <CreationFormImageGroup>
              {partner?.banner?.myprofile ? (
                <ThumbnailContainer
                  src={partner?.banner?.myprofile}
                  removeThumb={() => removeBanner('myprofile')}
                />
              ) : (
                <Button
                  type="button"
                  id="myprofile"
                  onClick={() => cutImage('myprofile')}
                >
                  Selecione a imagem para o Banner da página "Meu Perfil"
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="search" className="required">
              Banner Pesquisa (Caso não seja selecionada, será incluída a imagem
              padrão)
            </label>

            <CreationFormImageGroup>
              {partner?.banner?.search ? (
                <ThumbnailContainer
                  src={partner?.banner?.search}
                  removeThumb={() => removeBanner('search')}
                />
              ) : (
                <Button
                  type="button"
                  id="search"
                  onClick={() => cutImage('search')}
                >
                  Selecione a imagem para o Banner da página de Pesquisa
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormButtonGroup>
            <Button danger type="button" onClick={goToPartners}>
              Cancelar
            </Button>
            <Button primary>Salvar parceiro</Button>
          </CreationFormButtonGroup>
        </CreationForm>
      </TitleAndPartnerContainer>
    </CreateAndEditPartnersContainer>
  );
};

export default CreateAndEditPartners;
