import React from 'react';
import styled from 'styled-components';

const ViewVideoContainer = styled.div`
  flex: 1;
  width: 80vw;
  height: 65vh;
  padding: 24px;
  padding-top: 0;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

interface ViewVideoProps {
  reference: string;
}

const ViewVideo: React.FC<ViewVideoProps> = ({ reference }) => {
  return (
    <ViewVideoContainer>
      <iframe src={reference} frameBorder={0} title="vídeo"></iframe>
    </ViewVideoContainer>
  );
};

export default ViewVideo;
