import { createContext, Dispatch, SetStateAction } from 'react';

interface ITableContext {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  numberOfPages: number;
}

const TableContext = createContext({} as ITableContext);

export default TableContext;
