import React, { useContext, useMemo, useState } from 'react';
import {
  MenuChildren,
  MenuContainer,
  MenusContainer,
  MenusDivider,
} from './style';
import IMenu from 'src/models/menu';
import menus from './constants/menus';
import MenuTag from 'src/models/menu-tag';
import SidebarContext from 'src/contexts/SidebarContext';
import rightArrow from 'src/assets/right-arrow.svg';
import downArrow from 'src/assets/down-arrow.svg';
import AppContext from 'src/contexts/AppContext';

interface MenuProps {
  menu: IMenu;
}

const Menu: React.FC<MenuProps> = ({ menu }) => {
  const { opened, setOpened } = useContext(SidebarContext);
  const [showChildren, setShowChildren] = useState(false);

  const toggleShowChildren = () => {
    setOpened(true);
    setShowChildren(!showChildren);
  };

  return (
    <>
      <MenuContainer to={menu.url} onClick={toggleShowChildren}>
        <span className="icon">{menu.icon}</span>
        {opened && <span className="title">{menu.title}</span>}
        {opened && menu.children?.length ? (
          showChildren ? (
            <img src={downArrow} alt="down-arrow" />
          ) : (
            <img src={rightArrow} alt="right-arrow" />
          )
        ) : (
          <></>
        )}
      </MenuContainer>

      {opened && !!menu.children?.length && showChildren && (
        <MenuChildren>
          {menu.children.map((menu, index) => (
            <Menu key={index} menu={menu} />
          ))}
        </MenuChildren>
      )}
    </>
  );
};

const Menus: React.FC = () => {
  const { opened } = useContext(SidebarContext);
  const { selectedPartner } = useContext(AppContext);

  const untagedMenus = useMemo(() => {
    return menus.filter((menu) => !menu.tag);
  }, []);

  const customizationMenus = useMemo(() => {
    return menus.filter((menu) => menu.tag === MenuTag.customization);
  }, []);

  const otherMenus = useMemo(() => {
    return menus.filter((menu) => menu.tag === MenuTag.others);
  }, []);

  return (
    <MenusContainer>
      {untagedMenus.map((menu, index) => (
        <Menu key={index} menu={menu} />
      ))}

      <MenusDivider opened={opened}>
        {opened && <span>Personalização</span>}
      </MenusDivider>

      {customizationMenus
        .filter((m) =>
          !!selectedPartner
            ? `${m.title}`.toLocaleLowerCase() !== 'parceiros'
            : true
        )
        .map((menu, index) => (
          <Menu key={index} menu={menu} />
        ))}

      <MenusDivider opened={opened}>
        {opened && <span>Outros</span>}
      </MenusDivider>

      {otherMenus.map((menu, index) => (
        <Menu key={index} menu={menu} />
      ))}
    </MenusContainer>
  );
};

export default Menus;
