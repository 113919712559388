import React, { useState } from 'react';
import AppContext from 'src/contexts/AppContext';
import Partner from 'src/models/partner';

const AppContextProvider: React.FC = ({ children }) => {
  const [showUserContextMenu, setShowUserContextMenu] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<Partner | undefined>();

  return (
    <AppContext.Provider
      value={{
        showUserContextMenu,
        setShowUserContextMenu,
        selectedPartner,
        setSelectedPartner,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
