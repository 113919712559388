import styled, { css } from 'styled-components';

interface CreateAndEditThemesContainerProps {
  isOnModal: boolean;
}

const CreateAndEditThemesContainer = styled.div<CreateAndEditThemesContainerProps>`
  flex: 1;
  width: 100%;

  ${(props) =>
    props.isOnModal &&
    css`
      width: 80vw;
      height: 75vh;
      overflow-y: auto;
    `}
`;

const ContentCardContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  position: relative;
  border-top: solid 1px var(--border-gray);
  border-bottom: solid 1px var(--border-gray);
  padding: 4px 0;

  & .thumbnail {
    width: 160px;
  }

  & .name {
    flex: 1;
  }
`;

const SelectedContents = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const ContentCardButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-40%);
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
`;

export {
  CreateAndEditThemesContainer,
  ContentCardContainer,
  ContentCardButtonsContainer,
  SelectedContents,
};
