import axios from 'axios';
import cleanObjectForApi from 'src/helpers/clean-object-for-api';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

httpClient.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem('manole-api-token');
  if (config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.data) {
    cleanObjectForApi(config.data);
  }

  return config;
});

export default httpClient;
