import { createContext, Dispatch, SetStateAction } from 'react';

interface ISidebarContext {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

const SidebarContext = createContext({} as ISidebarContext);

export default SidebarContext;
