import styled from 'styled-components';

const TableAndTitleContainer = styled.div`
  background: white;
  border-radius: 4px;

  h2 {
    padding: 16px 24px;
  }
`;

export default TableAndTitleContainer;
