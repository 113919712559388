import Button from 'src/components/Button';
import styled from 'styled-components';

const CutImageContainer = styled.div`
  width: 80vw;
  height: 65vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const FileInputContainer = styled.div`
  padding-left: 4px;
  border-radius: 4px;
  border: solid 1px var(--primary);
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  input {
    flex: 1;
  }
`;

const SelectImageButton = styled(Button)`
  color: var(--gray);
  border-left: solid 1px var(--border-gray);
`;

export { CutImageContainer, SelectImageButton, FileInputContainer };
