import { createContext, Dispatch, SetStateAction } from 'react';
import Content from 'src/models/content';

interface IThemeCreationContext {
  selectedContents: Content[];
  setSelectedContents: Dispatch<SetStateAction<Content[]>>;
}

const ThemeCreationContext = createContext({} as IThemeCreationContext);

export default ThemeCreationContext;
