import React, { useState } from 'react';
import { AiFillFileExcel, AiOutlineFilePdf } from 'react-icons/ai';
import {
  FilterForm,
  FilterFormGroup,
  FilterFormInputGroup,
  FilterFormButtonGroup,
} from 'src/components/AppLayout/components/Filters';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import firstDayInPreviousMonth from 'src/helpers/first-day-previous-month';
import lastDayOnNextMonth from 'src/helpers/last-day-next-month';
import ContentsFinishedFilters from 'src/models/engagement-filters';
import { ContentsFinishedContainer } from './style';
import { getContentsFinishedReport } from 'src/services/reports';
import download from 'downloadjs';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import { Link } from 'react-router-dom';
import home from 'src/assets/home.svg';

const ContentsFinished: React.FC = () => {
  const [params, setParams] = useState({
    start_at_gt: firstDayInPreviousMonth(),
    start_at_lt: lastDayOnNextMonth(),
    finish_at_gt: firstDayInPreviousMonth(),
    finish_at_lt: lastDayOnNextMonth(),
  } as ContentsFinishedFilters);

  const handleChangeParams = (propName: string, newValue: string) => {
    setParams({ ...params, [propName]: newValue });
  };

  const downloadReport = async (type: string) => {
    const report = await getContentsFinishedReport(type, params);
    download(
      report,
      `Conteudos_Assitidos_${new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'short',
        timeStyle: 'short',
      }).format(new Date())}.${type}`
    );
  };

  return (
    <ContentsFinishedContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Conteúdos</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <Link to="/others/reports">Relatórios</Link>,
            <span>Conteúdos assistidos</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <FilterForm>
        <FilterFormGroup columns={2}>
          <FilterFormInputGroup>
            <label htmlFor="start_at_gt">Inicio em</label>
            <Input
              type="date"
              value={params.start_at_gt}
              max={params.start_at_lt}
              onChange={(e) =>
                handleChangeParams('start_at_gt', e.target.value)
              }
            />
          </FilterFormInputGroup>

          <FilterFormInputGroup>
            <label htmlFor="start_at_lt">Até</label>
            <Input
              type="date"
              value={params.start_at_lt}
              min={params.start_at_gt}
              onChange={(e) =>
                handleChangeParams('start_at_lt', e.target.value)
              }
            />
          </FilterFormInputGroup>
        </FilterFormGroup>

        <FilterFormGroup columns={2}>
          <FilterFormInputGroup>
            <label htmlFor="finish_at_gt">Fim em</label>
            <Input
              type="date"
              value={params.finish_at_gt}
              max={params.finish_at_lt}
              onChange={(e) =>
                handleChangeParams('finish_at_gt', e.target.value)
              }
            />
          </FilterFormInputGroup>

          <FilterFormInputGroup>
            <label htmlFor="finish_at_lt">Até</label>
            <Input
              type="date"
              value={params.finish_at_lt}
              min={params.finish_at_gt}
              onChange={(e) =>
                handleChangeParams('finish_at_lt', e.target.value)
              }
            />
          </FilterFormInputGroup>
        </FilterFormGroup>

        <FilterFormButtonGroup>
          <Button primary type="button" onClick={() => downloadReport('pdf')}>
            <AiOutlineFilePdf size={16} />
            <span>Relatório (PDF)</span>
          </Button>
          <Button primary type="button" onClick={() => downloadReport('csv')}>
            <AiFillFileExcel size={16} />
            <span>Relatório (CSV)</span>
          </Button>
        </FilterFormButtonGroup>
      </FilterForm>
    </ContentsFinishedContainer>
  );
};

export default ContentsFinished;
