import styled from 'styled-components';

const MediaCardContainer = styled.div`
  display: flex;
  align-items: stretch;
  padding: 2px 0;
  border-bottom: solid 2px var(--border-gray);
  gap: 8px;
  border-top: solid 2px var(--border-gray);
  cursor: pointer;
  transition: all 0.5s;

  & > .title {
    flex: 1;
    max-width: 50%;
    word-break: break-all;
    padding: 0 8px;
    border-left: solid 2px var(--border-gray);
    border-right: solid 2px var(--border-gray);
  }

  :hover {
    background: var(--border-gray);
  }
`;

const MediaThumb = styled.img`
  width: 160px;
`;

export { MediaCardContainer, MediaThumb };
