import React, { useState } from 'react';
import SpecialtyCreationContext from 'src/contexts/SpecialtyCreationContext';
import Theme from 'src/models/theme';

const SpecialtyCreationContextProvider: React.FC = ({ children }) => {
  const [selectedThemes, setSelectedThemes] = useState([] as Theme[]);

  return (
    <SpecialtyCreationContext.Provider
      value={{ selectedThemes, setSelectedThemes }}
    >
      {children}
    </SpecialtyCreationContext.Provider>
  );
};

export default SpecialtyCreationContextProvider;
