import { createContext, Dispatch, SetStateAction } from 'react';

interface ISearchContext {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}

const SearchContext = createContext({} as ISearchContext);

export default SearchContext;
