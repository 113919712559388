import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineCloudUpload, AiOutlineSearch } from 'react-icons/ai';
import MediaFromResponse, { MediaFile } from 'src/models/media';
import { getMedias } from 'src/services/medias';
import Button from '../Button';
import UploadProgressBar from './components/UploadProgressBar';
import {
  MediaFilters,
  SelectOrUploadMediaContainer,
  MediaList,
  ProcessingMediaCard,
} from './style';
import SambaUploader from 'samba-uploader';
import ModalContext from 'src/contexts/ModalContext';
import Input from '../Input';
import MediaCard from './components/MediaCard';
import Swal from 'sweetalert2';
import Loading from '../MinorLoading';
import videoExtensions from 'video-extensions';

interface ExtendedWindow extends Window {
  idOfRunningCheckForProcessingMediaJob: number;
  previousProcessingMediaNumber: number;
}

declare let window: ExtendedWindow;

export interface ReturnedMedia extends MediaFile {
  duration: number;
  type: string;
}

interface SelectSambaVideosContentProps {
  onSelectContent: (media: Partial<ReturnedMedia>) => void;
  modalId?: string;
}

const SelectOrUploadMedia: React.FC<SelectSambaVideosContentProps> = ({
  onSelectContent,
  modalId,
}) => {
  const { hideModal } = useContext(ModalContext);

  const uploadButton = useRef(null);

  const numberOfMediasPerPage = 10;

  const [loadingMoreMedias, setLoadingMoreMedias] = useState(false);
  const [shouldShowLoadMoreMedias, setShouldShowLoadMoreMedias] =
    useState(true);
  const [searchText, setSearchText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [medias, setMedias] = useState([] as MediaFromResponse[]);
  const [shouldShowLoading, setShouldShowLoading] = useState(true);
  const [sambaUploader, setSambaUploader] = useState({} as any);
  const [pageNumber, setPageNumber] = useState(1);
  const [processingMedias, setProcessingMedias] = useState(
    [] as { id: string; name: string; actualProcess: string }[]
  );

  const getSambaVideosContents = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setMedias([]);
    const localContents = await getMedias({
      search: searchText,
      limit: numberOfMediasPerPage,
      types: 'VIDEO',
    });
    if (localContents && localContents.length) {
      setMedias(localContents);
      setShouldShowLoading(true);
    } else {
      setShouldShowLoading(false);
    }
  };

  const loadMoreMedias = async () => {
    setLoadingMoreMedias(true);

    const localContents = await getMedias({
      search: searchText,
      start: pageNumber,
      limit: numberOfMediasPerPage,
      types: 'VIDEO',
    });

    if (localContents && localContents.length) {
      const localJoinedContents = [...medias, ...localContents];

      if (localJoinedContents.length % numberOfMediasPerPage > 0) {
        setShouldShowLoadMoreMedias(false);
      }

      setMedias(localJoinedContents);
      setShouldShowLoading(true);
    } else {
      setShouldShowLoading(false);
      setShouldShowLoadMoreMedias(false);
    }

    setPageNumber(pageNumber + 1);
    setLoadingMoreMedias(false);
  };

  const afterSelectContent = (media: Partial<ReturnedMedia>) => {
    onSelectContent(media);
    hideModal(modalId);
  };

  useEffect(
    () => {
      getSambaVideosContents();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (
      uploadButton.current &&
      (!sambaUploader || !Object.keys(sambaUploader).length)
    ) {
      setSambaUploader(
        new SambaUploader({
          button: uploadButton.current,
          sambaUploadKey: process.env.REACT_APP_SAMBA_UPLOAD_KEY,
          allowedExtensions: videoExtensions,
        })
      );
    }
  }, [uploadButton, sambaUploader]);

  useEffect(() => {
    if (sambaUploader && Object.keys(sambaUploader).length) {
      sambaUploader.on('start', () => {
        Swal.fire({
          title: 'Iniciando Upload',
          text: 'Upload de conteúdo iniciado!',
          timer: 1000,
        });
      });

      sambaUploader.on('progress', (event: any) => {
        const progress = (event.uploadedBytes / event.totalBytes) * 100;
        if (progress) {
          setUploadProgress(progress);
        }
      });

      sambaUploader.on('complete', async (event: any) => {
        Swal.fire({
          title: 'Sucesso!',
          icon: 'success',
          text:
            'Upload de conteúdo realizado com sucesso!' +
            '\nAgora será iniciado o processo de encoding do vídeo, em breve ele estará disponível para seleção abaixo!',
        });

        setUploadProgress(0);
      });
    }
  }, [sambaUploader]);

  useEffect(
    () => {
      const checkIfIsShowingSelectSambaVideosContent = () =>
        !!document.getElementById('select-samba-videos-content-container');

      const checkForNewProcessingMedias = async () => {
        const localProcessingMedias = await getMedias({
          status: 'PROCESSING',
        });

        if (!!localProcessingMedias.length) {
          setProcessingMedias(
            localProcessingMedias.map((md) => ({
              id: md.id,
              name: md.title,
              actualProcess: 'Processando',
            }))
          );
        } else {
          setProcessingMedias([]);
          if (window.previousProcessingMediaNumber > 0) {
            await getSambaVideosContents();
          }
        }

        window.previousProcessingMediaNumber = localProcessingMedias.length;

        if (checkIfIsShowingSelectSambaVideosContent()) {
          window.idOfRunningCheckForProcessingMediaJob = window.setTimeout(
            async () => await checkForNewProcessingMedias(),
            10000
          );
        } else {
          window.clearTimeout(window.idOfRunningCheckForProcessingMediaJob);
          window.idOfRunningCheckForProcessingMediaJob = 0;
        }
      };

      if (checkIfIsShowingSelectSambaVideosContent()) {
        if (!window.idOfRunningCheckForProcessingMediaJob) {
          window.setTimeout(
            async () => await checkForNewProcessingMedias(),
            150
          );
        } else {
          window.clearTimeout(window.idOfRunningCheckForProcessingMediaJob);
          window.idOfRunningCheckForProcessingMediaJob = window.setTimeout(
            async () => await checkForNewProcessingMedias(),
            150
          );
        }
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <SelectOrUploadMediaContainer id="select-samba-videos-content-container">
      <MediaFilters onSubmit={getSambaVideosContents}>
        <Input
          type="search"
          style={{ flex: 1 }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button type="submit" primary>
          <AiOutlineSearch size={16} />
        </Button>
        {uploadProgress ? (
          <UploadProgressBar progress={uploadProgress} />
        ) : (
          <Button type="button" primary ref={uploadButton}>
            <AiOutlineCloudUpload size={16} />
          </Button>
        )}
      </MediaFilters>

      <MediaList>
        {processingMedias?.length ? (
          processingMedias.map((pm) => (
            <ProcessingMediaCard key={pm.id}>
              <Loading medium />
              <p className="title">{pm.name}</p>
              <p className="status">{pm.actualProcess}...</p>
            </ProcessingMediaCard>
          ))
        ) : (
          <></>
        )}
        {medias?.length ? (
          medias.map((media, index) => (
            <MediaCard
              key={index}
              media={media}
              onSelectContent={afterSelectContent}
            />
          ))
        ) : shouldShowLoading ? (
          <Loading />
        ) : (
          <p className="not-found">
            Não foram encontrados conteúdos na sua conta do samba vídeos
          </p>
        )}
      </MediaList>

      {shouldShowLoadMoreMedias && (
        <Button onClick={loadMoreMedias}>
          {loadingMoreMedias ? <Loading small /> : 'Carregar mais'}
        </Button>
      )}
    </SelectOrUploadMediaContainer>
  );
};

export default SelectOrUploadMedia;
