import { createContext, Dispatch, SetStateAction } from 'react';

interface ILoadingContext {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const LoadingContext = createContext({} as ILoadingContext);

export default LoadingContext;
