import styled from 'styled-components';

const AppLayoutFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 60px;
  width: 100%;
  padding: 8px 24px;
  color: var(--black-60);
`;

export { AppLayoutFooterContainer };
