import React, { useContext } from 'react';
import AppContext from 'src/contexts/AppContext';
import AppContextProvider from '../AppContextProvider';
import LoadingContextProvider from '../LoadingContextProvider';
import ModalContextProvider from '../ModalContextProvider';
import SearchContextProvider from '../SearchContextProvider';
import SidebarContextProvider from '../SidebarContextProvider';
import SpecialtyCreationContextProvider from '../SpecialtyCreationContextProvider';
import ThemeCreationContextProvider from '../ThemeCreationContextProvider';
import AppLayoutFooter from './components/AppLayoutFooter';
import AppLayoutHeader from './components/AppLayoutHeader';
import AppLayoutSidebar from './components/AppLayoutSidebar';
import Loading from './components/Loading';
import Modals from './components/Modals';
import {
  AppLayoutContainer,
  AppLayoutContent,
  AppLayoutContentContainer,
} from './style';

const AppLayoutContentWrapper: React.FC = ({ children }) => {
  const { setShowUserContextMenu } = useContext(AppContext);

  const hideUserContextMenu = () => {
    setShowUserContextMenu(false);
  };

  return (
    <AppLayoutContentContainer onClick={hideUserContextMenu}>
      {children}
    </AppLayoutContentContainer>
  );
};

const AppLayout: React.FC = ({ children }) => {
  return (
    <ModalContextProvider>
      <SidebarContextProvider>
        <SearchContextProvider>
          <LoadingContextProvider>
            <SpecialtyCreationContextProvider>
              <ThemeCreationContextProvider>
                <AppContextProvider>
                  <AppLayoutContainer>
                    <AppLayoutSidebar />
                    <AppLayoutContentWrapper>
                      <AppLayoutHeader />
                      <AppLayoutContent>{children}</AppLayoutContent>
                      <AppLayoutFooter />
                    </AppLayoutContentWrapper>
                    <Modals />
                  </AppLayoutContainer>
                  <Loading />
                </AppContextProvider>
              </ThemeCreationContextProvider>
            </SpecialtyCreationContextProvider>
          </LoadingContextProvider>
        </SearchContextProvider>
      </SidebarContextProvider>
    </ModalContextProvider>
  );
};

export default AppLayout;
