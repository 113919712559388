import React, { cloneElement } from 'react';
import { BreadcrumbContainer } from './style';

interface BreadcrumbProps {
  crumbs: JSX.Element[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ crumbs }) => {
  return (
    <BreadcrumbContainer>
      {crumbs.map((crumb, index) => {
        const clonedCrumb = cloneElement(crumb, { key: index });

        return (
          <>
            {clonedCrumb}
            <span key={`${index}-`}>{index < crumbs.length - 1 && ' - '}</span>
          </>
        );
      })}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
