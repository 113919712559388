import Button from 'src/components/Button';
import styled from 'styled-components';

const HeaderUserContainer = styled.div`
  position: relative;
`;

const ToggleUserMenu = styled(Button)`
  background: transparent;
  color: var(--white);
  gap: 4px;
  padding: 8px;

  :hover {
    box-shadow: none;
    background: var(--white);
    color: var(--primary);
  }
`;

const UserAvatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

const UserName = styled.span``;

const UserContextMenuContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 4px));
  background: var(--white);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const UserContextMenuItem = styled(Button)`
  border: solid 1px var(--primary);
  font-size: 0.8em;

  :hover {
    background: var(--primary);
    color: var(--white);
  }
`;

export {
  HeaderUserContainer,
  ToggleUserMenu,
  UserAvatar,
  UserName,
  UserContextMenuContainer,
  UserContextMenuItem,
};
