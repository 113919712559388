import styled from 'styled-components';

const AppLayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(94, 114, 228, 1) 40%,
    rgba(255, 255, 255, 1) 40%
  );

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const AppLayoutContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const AppLayoutContent = styled.div`
  flex: 1;
  padding: 0 24px;
  width: 100%;
`;

export { AppLayoutContainer, AppLayoutContentContainer, AppLayoutContent };
