import styled, { css } from 'styled-components';

interface ThemesContainerProps {
  isOnModal: boolean;
}

const ThemesContainer = styled.div<ThemesContainerProps>`
  flex: 1;
  width: 100%;

  ${(props) =>
    props.isOnModal &&
    css`
      width: 80vw;
      height: 75vh;
      overflow-y: auto;
    `}
`;

const SelectedThemes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  & .selected-themes {
    flex: 1;
    max-width: 100%;
  }
`;

export { ThemesContainer, SelectedThemes };
