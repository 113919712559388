import httpClient from 'src/http-client';
import ExtraMaterial from 'src/models/extra-material';
import Paginated from 'src/models/paginated';

const getExtraMaterials = async (filters?: any) => {
  return (
    await httpClient.get<Paginated<ExtraMaterial>>(`/extra-materials`, {
      params: { ...filters },
    })
  ).data;
};

const getExtraMaterial = async (extraMaterialId: string) => {
  return (
    await httpClient.get<ExtraMaterial>(`/extra-materials/${extraMaterialId}`)
  ).data;
};

const createExtraMaterial = async (
  newExtraMaterial: Partial<ExtraMaterial>
) => {
  return (
    await httpClient.post(`/extra-materials`, {
      ...newExtraMaterial,
    })
  ).data;
};

const updateExtraMaterial = async (
  extraMaterialId: string,
  newExtraMaterial: Partial<ExtraMaterial>
) => {
  delete newExtraMaterial.extra_material_id;
  delete newExtraMaterial.created_at;
  delete newExtraMaterial.updated_at;

  return (
    await httpClient.put(`/extra-materials/${extraMaterialId}`, {
      ...newExtraMaterial,
    })
  ).data;
};

const deleteExtraMaterial = async (extraMaterialId: string) => {
  await httpClient.delete(`/extra-materials/${extraMaterialId}`);
};

export {
  getExtraMaterials,
  getExtraMaterial,
  createExtraMaterial,
  updateExtraMaterial,
  deleteExtraMaterial,
};
