import styled, { css } from 'styled-components';

interface CreateAndEditAuthorsContainerProps {
  isOnModal: boolean;
}

const CreateAndEditAuthorsContainer = styled.div<CreateAndEditAuthorsContainerProps>`
  flex: 1;
  width: 100%;

  ${(props) =>
    props.isOnModal &&
    css`
      width: 80vw;
      height: 75vh;
      overflow-y: auto;
    `}
`;

export { CreateAndEditAuthorsContainer };
