import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import { CreateAndEditAuthorsContainer } from './style';
import home from 'src/assets/home.svg';
import {
  CreationForm,
  CreationFormButtonGroup,
  CreationFormGroup,
  CreationFormImageGroup,
} from 'src/components/AppLayout/components/Forms';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import TitleAndAuthorContainer from 'src/components/AppLayout/components/TableAndTitleContainer';
import showErrorMessage from 'src/helpers/show-error-message';
import Author from 'src/models/author';
import checkEmptyString from 'src/helpers/check-empty-string';
import SystemError from 'src/models/error';
import showSuccessMessage from 'src/helpers/show-success-message';
import {
  createAuthor as createAuthorService,
  updateAuthor as updateAuthorService,
  getAuthor as getAuthorService,
} from 'src/services/authors';
import useLoading from 'src/hooks/useLoading';
import TextArea from 'src/components/AppLayout/components/TextArea';
import ThumbnailContainer from 'src/components/AppLayout/components/ThumbnailContainer';
import CutImage from 'src/components/AppLayout/components/CutImage';
import { uploadFile } from 'src/services/files';
import useModal from 'src/hooks/useModal';

interface CreateAndEditAuthorsParams {
  authorId?: string;
}

interface CreateAndEditAuthorsProps {
  modalId?: string;
  authorId?: string;
  onUpdateOrSave?: () => void;
}

const CreateAndEditAuthors: React.FC<CreateAndEditAuthorsProps> = ({
  modalId,
  authorId: propsAuthorId,
  onUpdateOrSave,
}) => {
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { hideModal, showModal } = useModal();
  const { authorId: paramsAuthorId } = useParams<CreateAndEditAuthorsParams>();
  const [author, setAuthor] = useState({} as Author);

  const authorId = useMemo(() => {
    return propsAuthorId || paramsAuthorId;
  }, [propsAuthorId, paramsAuthorId]);

  const handleChangeAuthor = (propName: string, value: any) => {
    setAuthor((author) => {
      return { ...author, [propName]: value };
    });
  };

  const validate = () => {
    if (checkEmptyString(author.name)) {
      throw new Error('Informe um nome para o autor');
    }

    if (!author?.images?.avatar) {
      throw new Error('Informe uma imagem de avatar para o autor');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      author.description = author.description || '';

      if (isEditing) {
        await updateAuthorService(authorId!, author);
        showSuccessMessage('Autor editado com sucesso!');
      } else {
        await createAuthorService(author);
        showSuccessMessage('Autor cadastrado com sucesso!');
      }

      goToAuthors();
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  };

  const getAuthor = useCallback(async () => {
    if (authorId) {
      setIsLoading(true);

      const author = await getAuthorService(authorId);
      setAuthor(author);

      setIsLoading(false);
    }
  }, [authorId, setIsLoading]);

  const goToAuthors = () => {
    if (modalId) {
      onUpdateOrSave && onUpdateOrSave();
      hideModal(modalId);
    } else {
      history.push('/customization/academic/authors');
    }
  };

  const cutImage = () => {
    showModal(
      'Cortar imagem',
      <CutImage onCutImage={uploadAndSelectImage} aspect={500 / 600} />
    );
  };

  const uploadAndSelectImage = async (image: File) => {
    setIsLoading(true);

    try {
      const { reference } = await uploadFile(image);
      handleChangeAuthor('images', {
        ...author.images,
        avatar: reference,
      });
    } catch (e) {
      showErrorMessage(e as SystemError);
    }

    setIsLoading(false);
  };

  const removeThumb = () => {
    setAuthor((content) => ({
      ...(content || {}),
      images: { avatar: '' },
    }));
  };

  const isEditing = useMemo(() => {
    return !!authorId;
  }, [authorId]);

  useEffect(() => {
    getAuthor();
  }, [getAuthor]);

  return (
    <CreateAndEditAuthorsContainer isOnModal={!!modalId}>
      {!modalId ? (
        <TitleAndBreadcrumbContainer>
          <PageTitle>{isEditing ? 'Editar' : 'Criar'} Autor</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/academic">Acadêmico</Link>,
              <Link to="/customization/academic/authors">Autores</Link>,
              <span>{isEditing ? 'Editar' : 'Criar'} Autores</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      ) : (
        <></>
      )}

      <TitleAndAuthorContainer>
        <PageTitle dark medium>
          {isEditing ? 'Editar' : 'Criar'} Autor
        </PageTitle>

        <CreationForm onSubmit={handleSubmit}>
          <CreationFormGroup>
            <label htmlFor="name" className="required">
              Nome
            </label>
            <Input
              id="name"
              placeholder="Nome"
              value={author.name}
              onChange={(e) => handleChangeAuthor('name', e.target.value)}
              required
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="description">Descrição</label>
            <TextArea
              id="description"
              placeholder="Descrição"
              value={author.description}
              onChange={(e) =>
                handleChangeAuthor('description', e.target.value)
              }
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="specialization">Especialização</label>
            <Input
              id="specialization"
              placeholder="Especialização"
              value={author?.info?.specialization}
              onChange={(e) =>
                handleChangeAuthor('info', {
                  ...(author.info || {}),
                  specialization: e.target.value,
                })
              }
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="select-image" className="required">
              Foto
            </label>
            <CreationFormImageGroup>
              {author?.images?.avatar ? (
                <ThumbnailContainer
                  src={author?.images?.avatar}
                  removeThumb={removeThumb}
                />
              ) : (
                <Button type="button" id="select-image" onClick={cutImage}>
                  Selecione a imagem para o avatar do autor
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormButtonGroup>
            <Button danger type="button" onClick={goToAuthors}>
              Cancelar
            </Button>
            <Button primary>Salvar autor</Button>
          </CreationFormButtonGroup>
        </CreationForm>
      </TitleAndAuthorContainer>
    </CreateAndEditAuthorsContainer>
  );
};

export default CreateAndEditAuthors;
