import React, { useContext } from 'react';
import {
  HeaderUserContainer,
  ToggleUserMenu,
  UserAvatar,
  UserContextMenuContainer,
  UserContextMenuItem,
  UserName,
} from './style';
import defaultUserImage from 'src/assets/default-user-image.png';
import AuthContext from 'src/contexts/AuthContext';
import { useHistory } from 'react-router';
import AppContext from 'src/contexts/AppContext';
import useModal from 'src/hooks/useModal';
import SelectPartner from '../../../SelectPartner';

const UserContextMenu: React.FC = () => {
  const history = useHistory();
  const { showModal } = useModal();

  const logout = () => {
    window.localStorage.removeItem('manole-api-token');
    history.push('/');
  };

  const changePartner = () => {
    showModal('Selecione um Parceiro para Administrar', <SelectPartner />);
  };

  return (
    <UserContextMenuContainer>
      <UserContextMenuItem onClick={changePartner}>
        Administrar Parceiro
      </UserContextMenuItem>
      <UserContextMenuItem onClick={logout}>Sair</UserContextMenuItem>
    </UserContextMenuContainer>
  );
};

const HeaderUser: React.FC = () => {
  const { showUserContextMenu, setShowUserContextMenu, selectedPartner } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);

  const toogleShowUserContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();

    setShowUserContextMenu((show) => !show);
  };

  return (
    <HeaderUserContainer>
      <ToggleUserMenu onClick={toogleShowUserContextMenu}>
        <UserAvatar alt="avatar" src={defaultUserImage} />
        <UserName>
          {user?.name || 'Desconhecido'}{' '}
          {!!selectedPartner?.name && `(${selectedPartner.name})`}
        </UserName>
      </ToggleUserMenu>
      {showUserContextMenu && <UserContextMenu />}
    </HeaderUserContainer>
  );
};

export default HeaderUser;
