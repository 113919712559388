import styled from 'styled-components';

const ModalsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--black-60);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 32px 0;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { ModalsContainer };
