import React, { useEffect, useMemo, useRef, useState } from 'react';
import Pagination from './components/Pagination';
import TableContext from './contexts/TableContext';
import { TableContainer } from './style';
import clamp from 'clamp-js';

export interface HeaderConfig {
  propName: string;
  label: string | JSX.Element;
  attributes?: React.StyleHTMLAttributes<HTMLElement>;
}

interface Row {
  rowColor?: string;
  [prop: string]: any;
}

interface TableProps {
  headerConfigs: HeaderConfig[];
  items: Row[];
  itemsPerPage?: number;
}

const Table: React.FC<TableProps> = ({
  headerConfigs,
  items,
  itemsPerPage = 5,
}) => {
  const [page, setPage] = useState(1);
  const tableRef = useRef<HTMLTableElement>(null);

  const numberOfPages = useMemo(() => {
    return Math.ceil(items?.length / itemsPerPage);
  }, [items, itemsPerPage]);

  const showPagination = useMemo(() => {
    return items?.length > itemsPerPage;
  }, [items, itemsPerPage]);

  const itemsToShow = useMemo(() => {
    return [...(items || [])].splice(itemsPerPage * (page - 1), itemsPerPage);
  }, [items, page, itemsPerPage]);

  useEffect(() => {
    if (tableRef.current && itemsToShow.length) {
      const cells = tableRef.current.querySelectorAll(
        '.table-data-content'
      ) as NodeListOf<HTMLSpanElement>;
      for (let cell of Array.from(cells || [])) {
        clamp(cell, { clamp: 5 });
      }
    }
  }, [itemsToShow.length]);

  return (
    <TableContext.Provider value={{ page, setPage, numberOfPages }}>
      <TableContainer ref={tableRef}>
        <table>
          <thead>
            <tr>
              {(headerConfigs || []).map((hc, index) => (
                <th {...hc.attributes} key={index}>
                  {hc.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(itemsToShow || []).length ? (
              (itemsToShow || []).map((item, index) => (
                <tr
                  key={index}
                  style={{ backgroundColor: item.rowColor || 'auto' }}
                >
                  {(headerConfigs || []).map((hc, index) => (
                    <td key={index}>
                      <span className="table-data-content">
                        {item[hc.propName]}
                      </span>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headerConfigs.length}>
                  Não foram encontrados itens para a lista
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {showPagination && <Pagination />}
      </TableContainer>
    </TableContext.Provider>
  );
};

export default Table;
