import styled, { css } from 'styled-components';

interface AuthorsContainerProps {
  isOnModal: boolean;
}

const AuthorsContainer = styled.div<AuthorsContainerProps>`
  flex: 1;
  width: 100%;

  ${(props) =>
    props.isOnModal &&
    css`
      width: 80vw;
      height: 75vh;
      overflow-y: auto;
    `}
`;

export { AuthorsContainer };
