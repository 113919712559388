import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import home from 'src/assets/home.svg';
import FeatureCard from 'src/components/AppLayout/components/FeatureCard';
import Button from 'src/components/Button';
import { ReportsContainer, ReportsFeatureList } from './style';

const Reports: React.FC = () => {
  const history = useHistory();

  const goTo = (address: string) => {
    history.push(address);
  };

  return (
    <ReportsContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Relatórios</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Relatórios</strong>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <ReportsFeatureList>
        <FeatureCard>
          <span className="title">Engajamento</span>

          <span className="description">
            Gere um relatório com as informações de engajamento
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/reports/engagement')}
          >
            Ver mais
          </Button>
        </FeatureCard>

        <FeatureCard>
          <span className="title">Conteúdos assistidos</span>

          <span className="description">
            Gere um relatório com as informações sobre os conteúdos assistidos
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/reports/contents-finished')}
          >
            Ver mais
          </Button>
        </FeatureCard>
      </ReportsFeatureList>
    </ReportsContainer>
  );
};

export default Reports;
