import styled, { css } from 'styled-components';
import Button from '../Button';

interface LoginLayoutContainerProps {
  background: string;
}

const LoginLayoutContainer = styled.div<LoginLayoutContainerProps>`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      183deg,
      rgba(94, 114, 228, 0.85) 49.9%,
      rgba(23, 43, 77, 1) 50%
    )
    ${(props) => (props.background ? css`, url(${props.background})` : '')};
  background-position: top;
  background-repeat: no-repeat;
  background-size: 130%;

  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
`;

const LoginLayoutHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 24px;
`;

const LoginLayoutContact = styled(Button)`
  padding: 8px 32px;
  font-weight: bold;
`;

const LoginLayoutLogo = styled.img``;

const LoginLayoutContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LoginLayoutFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 24px;
  color: var(--gray);
`;

const LoginLayoutCopy = styled.p``;

export {
  LoginLayoutContainer,
  LoginLayoutContent,
  LoginLayoutHeader,
  LoginLayoutFooter,
  LoginLayoutContact,
  LoginLayoutLogo,
  LoginLayoutCopy,
};
