import Menu from 'src/models/menu';
import start from 'src/assets/start.svg';
// import homeCustomization from 'src/assets/home-customization.svg';
// import exam from 'src/assets/exam.svg';
// import faqs from 'src/assets/faqs.svg';
// import forum from 'src/assets/forum.svg';
// import institutional from 'src/assets/institutional.svg';
import reports from 'src/assets/reports.svg';
import users from 'src/assets/users.svg';
import academic from 'src/assets/academic.svg';
import MenuTag from 'src/models/menu-tag';
import { FaUsers } from 'react-icons/fa';

const menus: Menu[] = [
  { icon: <img src={start} alt="home" />, title: 'Início', url: '/home' },
  {
    icon: <FaUsers />,
    title: 'Parceiros',
    url: '/customization/partners',
    tag: MenuTag.customization,
  },
  {
    icon: <img src={academic} alt="academic" />,
    title: 'Acadêmico',
    url: '/customization/academic',
    tag: MenuTag.customization,
    children: [
      { title: 'Especialidades', url: '/customization/academic/specialties' },
      { title: 'Temas', url: '/customization/academic/themes' },
      { title: 'Conteúdos', url: '/customization/academic/contents' },
      { title: 'Autores', url: '/customization/academic/authors' },
    ],
  },
  // {
  //   icon: <img src={exam} alt="exam" />,
  //   title: 'Avaliação',
  //   url: '/customization/exam',
  //   tag: MenuTag.customization,
  // },
  // {
  //   icon: <img src={faqs} alt="faqs" />,
  //   title: 'FAQs',
  //   url: '/customization/faqs',
  //   tag: MenuTag.customization,
  // },
  // {
  //   icon: <img src={forum} alt="forum" />,
  //   title: 'Fórum',
  //   url: '/customization/forum',
  //   tag: MenuTag.customization,
  // },
  // {
  //   icon: <img src={institutional} alt="institutional" />,
  //   title: 'Institucional',
  //   url: '/customization/institutional',
  //   tag: MenuTag.customization,
  // },
  {
    icon: <img src={reports} alt="reports" />,
    title: 'Relatórios',
    url: '/others/reports',
    tag: MenuTag.others,
    children: [
      { title: 'Engajamento', url: '/others/reports/engagement' },
      {
        title: 'Conteúdos assistidos',
        url: '/others/reports/contents-finished',
      },
    ],
  },
  {
    icon: <img src={users} alt="users" />,
    title: 'Usuários',
    url: '/others/users',
    tag: MenuTag.others,
  },
];

export default menus;
