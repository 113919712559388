import styled from 'styled-components';

const CreateAndSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  button {
    padding: 12px 16px;
    font-size: 1rem;
  }
`;

export default CreateAndSearchContainer;
