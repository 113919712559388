import React from 'react';
import HeaderSearch from './components/HeaderSearch';
import HeaderUser from './components/HeaderUser';
import { AppLayoutHeaderContainer } from './style';

const AppLayoutHeader: React.FC = () => {
  return (
    <AppLayoutHeaderContainer>
      <HeaderSearch />

      <HeaderUser />
    </AppLayoutHeaderContainer>
  );
};

export default AppLayoutHeader;
