import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginLayout from '../components/LoginLayout';
import AppLayout from '../components/AppLayout';
import Login from '../pages/login';
import Home from 'src/pages/home';
import Contents from 'src/pages/customization/Academic/pages/Contents';
import Academic from 'src/pages/customization/Academic';
import CreateAndEditContents from 'src/pages/customization/Academic/pages/CreateAndEditContents';
import Themes from 'src/pages/customization/Academic/pages/Themes';
import CreateAndEditThemes from 'src/pages/customization/Academic/pages/CreateAndEditThemes';
import Specialties from 'src/pages/customization/Academic/pages/Specialties';
import CreateAndEditSpecialties from 'src/pages/customization/Academic/pages/CreateAndEditSpecialties';
import Authors from 'src/pages/customization/Academic/pages/Authors';
import CreateAndEditAuthors from 'src/pages/customization/Academic/pages/CreateAndEditAuthors';
import Partners from 'src/pages/customization/Partners';
import CreateAndEditPartners from 'src/pages/customization/Academic/pages/CreateAndEditPartners';
import Reports from 'src/pages/others/reports';
import Engagement from 'src/pages/others/reports/pages/Engagement';
import ContentsFinished from 'src/pages/others/reports/pages/ContentsFinished';
import Users from 'src/pages/others/users';
import SearchResults from 'src/pages/SearchResults';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={['/', '/login', '/forgot-password', '/create-account']}
      >
        <LoginLayout>
          <Login />
        </LoginLayout>
      </Route>

      <Route exact>
        <AppLayout>
          <Switch>
            <Route path="/home" exact>
              <Home />
            </Route>

            <Route path={['/customization']}>
              <Switch>
                <Route path={['/customization/partners']}>
                  <Route path="/customization/partners/" exact>
                    <Partners />
                  </Route>

                  <Route path="/customization/partners/create-partner" exact>
                    <CreateAndEditPartners />
                  </Route>
                  <Route
                    path="/customization/partners/edit-partner/:partnerId"
                    exact
                  >
                    <CreateAndEditPartners />
                  </Route>
                </Route>

                <Route path={['/customization/academic']}>
                  <Switch>
                    <Route path="/customization/academic/" exact>
                      <Academic />
                    </Route>

                    <Route path="/customization/academic/contents" exact>
                      <Contents />
                    </Route>
                    <Route
                      path="/customization/academic/contents/create-content"
                      exact
                    >
                      <CreateAndEditContents />
                    </Route>
                    <Route
                      path="/customization/academic/contents/edit-content/:contentId"
                      exact
                    >
                      <CreateAndEditContents />
                    </Route>

                    <Route path="/customization/academic/themes" exact>
                      <Themes />
                    </Route>
                    <Route
                      path="/customization/academic/themes/create-theme"
                      exact
                    >
                      <CreateAndEditThemes />
                    </Route>
                    <Route
                      path="/customization/academic/themes/edit-theme/:themeId"
                      exact
                    >
                      <CreateAndEditThemes />
                    </Route>

                    <Route path="/customization/academic/specialties" exact>
                      <Specialties />
                    </Route>
                    <Route
                      path="/customization/academic/specialties/create-specialty"
                      exact
                    >
                      <CreateAndEditSpecialties />
                    </Route>
                    <Route
                      path="/customization/academic/specialties/edit-specialty/:specialtyId"
                      exact
                    >
                      <CreateAndEditSpecialties />
                    </Route>

                    <Route path="/customization/academic/authors" exact>
                      <Authors />
                    </Route>
                    <Route
                      path="/customization/academic/authors/create-author"
                      exact
                    >
                      <CreateAndEditAuthors />
                    </Route>
                    <Route
                      path="/customization/academic/authors/edit-author/:authorId"
                      exact
                    >
                      <CreateAndEditAuthors />
                    </Route>
                  </Switch>
                </Route>
              </Switch>
            </Route>

            <Route path={['/others']}>
              <Switch>
                <Route path={['/others/reports']}>
                  <Switch>
                    <Route path="/others/reports/" exact>
                      <Reports />
                    </Route>

                    <Route path="/others/reports/engagement" exact>
                      <Engagement />
                    </Route>

                    <Route path="/others/reports/contents-finished" exact>
                      <ContentsFinished />
                    </Route>
                  </Switch>
                </Route>
                <Route path="/others/users">
                  <Users />
                </Route>
              </Switch>
            </Route>

            <Route path="/search/:query" exact>
              <SearchResults />
            </Route>

          </Switch>
        </AppLayout>
      </Route>
    </Switch>
  );
};

export default Routes;
