import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import {
  ThemeCardContainer,
  CreateAndEditSpecialtiesContainer,
  SelectedThemes,
  ThemeCardButtonsContainer,
} from './style';
import home from 'src/assets/home.svg';
import {
  CreationForm,
  CreationFormButtonGroup,
  CreationFormGroup,
  CreationFormImageGroup,
} from 'src/components/AppLayout/components/Forms';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import TitleAndSpecialtyContainer from 'src/components/AppLayout/components/TableAndTitleContainer';
import ModalContext from 'src/contexts/ModalContext';
import showErrorMessage from 'src/helpers/show-error-message';
import Specialty from 'src/models/specialty';
import checkEmptyString from 'src/helpers/check-empty-string';
import SystemError from 'src/models/error';
import showSuccessMessage from 'src/helpers/show-success-message';
import {
  createSpecialty as createSpecialtyService,
  updateSpecialty as updateSpecialtyService,
  getSpecialty as getSpecialtyService,
} from 'src/services/specialties';
import useLoading from 'src/hooks/useLoading';
import SpecialtyCreationContext from 'src/contexts/SpecialtyCreationContext';
import { BsTrashFill } from 'react-icons/bs';
import { AiOutlineUp, AiOutlineDown } from 'react-icons/ai';
import Theme from 'src/models/theme';
import Themes from '../Themes';
import TextArea from 'src/components/AppLayout/components/TextArea';
import ThumbnailContainer from 'src/components/AppLayout/components/ThumbnailContainer';
import CutImage from 'src/components/AppLayout/components/CutImage';
import { uploadFile } from 'src/services/files';
import AppContext from 'src/contexts/AppContext';

interface CreateAndEditSpecialtiesParams {
  specialtyId?: string;
}

interface ThemeCardProps {
  theme: Theme;
  handlePositionChange: (theme: Theme, up: boolean) => void;
  isFirst: boolean;
  isLast: boolean;
}

const ThemeCard: React.FC<ThemeCardProps> = ({
  theme,
  handlePositionChange,
  isFirst,
  isLast,
}) => {
  const { setSelectedThemes } = useContext(SpecialtyCreationContext);

  const removeSelectedSpecialty = () => {
    setSelectedThemes((selectedThemes) =>
      (selectedThemes || []).filter((t) => t.content_id !== theme.content_id)
    );
  };

  return (
    <ThemeCardContainer>
      <p className="name">{theme.name}</p>
      <ThemeCardButtonsContainer>
        <Button
          circle
          small
          dangerFill
          type="button"
          onClick={removeSelectedSpecialty}
        >
          <BsTrashFill />
        </Button>
        {!isFirst && (
          <Button
            type="button"
            circle
            small
            primaryFill
            onClick={() => handlePositionChange(theme, true)}
          >
            <AiOutlineUp />
          </Button>
        )}
        {!isLast && (
          <Button
            type="button"
            circle
            small
            primaryFill
            onClick={() => handlePositionChange(theme, false)}
          >
            <AiOutlineDown />
          </Button>
        )}
      </ThemeCardButtonsContainer>
    </ThemeCardContainer>
  );
};

const CreateAndEditSpecialties: React.FC = () => {
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { showModal } = useContext(ModalContext);
  const { specialtyId } = useParams<CreateAndEditSpecialtiesParams>();
  const [specialty, setSpecialty] = useState({} as Specialty);
  const { selectedThemes, setSelectedThemes } = useContext(
    SpecialtyCreationContext
  );
  const { selectedPartner } = useContext(AppContext);

  const handleChangeSpecialty = (propName: string, value: any) => {
    setSpecialty((specialty) => {
      return { ...specialty, [propName]: value };
    });
  };

  const validate = () => {
    if (checkEmptyString(specialty.name)) {
      throw new Error('Informe um nome para a especialidade');
    }

    if (checkEmptyString(specialty.description)) {
      throw new Error('Informe uma descrição para a especialidade');
    }

    if (!specialty?.children?.length) {
      throw new Error('Informe ao menos um tema para a especialidade');
    }

    if (!specialty.images?.thumbnail) {
      throw new Error('Informe uma imagem de capa para a especialidade');
    }

    if (!specialty.images?.banner) {
      throw new Error('Informe uma imagem para o banner da especialidade');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      specialty.children = (selectedThemes || []).map((c, index) => ({
        child_id: c.content_id,
        position: index + 1,
      }));

      validate();

      if (selectedPartner?.segmentation_item_id) {
        specialty.segmentation_items = [
          { segmentation_item_id: selectedPartner.segmentation_item_id },
        ];
      }

      if (isEditing) {
        await updateSpecialtyService(specialtyId!, specialty);
        showSuccessMessage('Especialidade editada com sucesso!');
      } else {
        await createSpecialtyService(specialty);
        showSuccessMessage('Especialidade cadastrada com sucesso!');
      }

      goToSpecialties();
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  };

  const getSpecialty = useCallback(async () => {
    if (specialtyId) {
      setIsLoading(true);

      const specialty = await getSpecialtyService(specialtyId);
      setSelectedThemes(specialty.children as Theme[]);
      setSpecialty(specialty);

      setIsLoading(false);
    }
  }, [specialtyId, setIsLoading, setSelectedThemes]);

  const goToSpecialties = () => {
    history.push('/customization/academic/specialties');
  };

  const selectThemes = () => {
    showModal('Selecionar temas', <Themes />);
  };

  const cutThumb = () => {
    showModal(
      'Cortar imagem de capa',
      <CutImage onCutImage={uploadAndSelectThumb} aspect={1.67} />
    );
  };

  const uploadAndSelectThumb = async (image: File) => {
    setIsLoading(true);

    try {
      const { reference } = await uploadFile(image);
      handleChangeSpecialty('images', {
        ...specialty.images,
        thumbnail: reference,
      });
    } catch (e) {
      showErrorMessage(e as SystemError);
    }

    setIsLoading(false);
  };

  const removeThumb = () => {
    handleChangeSpecialty('images', {
      ...specialty.images,
      thumbnail: '',
    });
  };

  const cutBanner = () => {
    showModal(
      'Cortar imagem do banner',
      <CutImage onCutImage={uploadAndSelectBanner} aspect={1.67} />
    );
  };

  const uploadAndSelectBanner = async (image: File) => {
    setIsLoading(true);

    try {
      const { reference } = await uploadFile(image);
      handleChangeSpecialty('images', {
        ...specialty.images,
        banner: reference,
      });
    } catch (e) {
      showErrorMessage(e as SystemError);
    }

    setIsLoading(false);
  };

  const removeBanner = () => {
    handleChangeSpecialty('images', { ...specialty.images, banner: '' });
  };

  const isEditing = useMemo(() => {
    return !!specialtyId;
  }, [specialtyId]);

  const isFirstTheme = useCallback(
    (theme: Theme) => {
      const indexOfTheme = selectedThemes.indexOf(theme);
      return indexOfTheme === 0;
    },
    [selectedThemes]
  );

  const isLastTheme = useCallback(
    (theme: Theme) => {
      const indexOfTheme = selectedThemes.indexOf(theme);
      return indexOfTheme === selectedThemes.length - 1;
    },
    [selectedThemes]
  );

  const handleMoveUp = (theme: Theme) => {
    const indexOfTheme = selectedThemes.indexOf(theme);

    if (isFirstTheme(theme)) {
      return;
    }

    const newTheme = selectedThemes.splice(indexOfTheme, 1);
    selectedThemes.splice(indexOfTheme - 1, 0, newTheme[0]);
  };

  const handleMoveDown = (theme: Theme) => {
    const indexOfTheme = selectedThemes.indexOf(theme);

    if (isLastTheme(theme)) {
      return;
    }

    const newTheme = selectedThemes.splice(indexOfTheme, 1);
    selectedThemes.splice(indexOfTheme + 1, 0, newTheme[0]);
  };

  const handleChangePosition = (theme: Theme, up: boolean) => {
    if (up) {
      handleMoveUp(theme);
    } else {
      handleMoveDown(theme);
    }
    setSelectedThemes([...selectedThemes]);
  };

  useEffect(() => {
    if (!isEditing) {
      setSelectedThemes([]);
    }
  }, [isEditing, setSelectedThemes]);

  useEffect(() => {
    getSpecialty();
  }, [getSpecialty]);

  return (
    <CreateAndEditSpecialtiesContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>{isEditing ? 'Editar' : 'Criar'} Especialidade</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Personalização</strong>,
            <Link to="/customization/academic">Acadêmico</Link>,
            <Link to="/customization/academic/specialties">
              Especialidades
            </Link>,
            <span>{isEditing ? 'Editar' : 'Criar'} Especialidades</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <TitleAndSpecialtyContainer>
        <PageTitle dark medium>
          {isEditing ? 'Editar' : 'Criar'} Especialidade
        </PageTitle>

        <CreationForm onSubmit={handleSubmit}>
          <CreationFormGroup>
            <label htmlFor="name" className="required">
              Nome
            </label>
            <Input
              id="name"
              placeholder="Nome"
              value={specialty.name}
              onChange={(e) => handleChangeSpecialty('name', e.target.value)}
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="description" className="required">
              Descrição
            </label>
            <TextArea
              id="description"
              placeholder="Título"
              value={specialty.description}
              onChange={(e) =>
                handleChangeSpecialty('description', e.target.value)
              }
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="select-image" className="required">
              Imagem de capa
            </label>
            <CreationFormImageGroup>
              {specialty?.images?.thumbnail ? (
                <ThumbnailContainer
                  src={specialty?.images?.thumbnail}
                  removeThumb={removeThumb}
                />
              ) : (
                <Button type="button" id="select-image" onClick={cutThumb}>
                  Selecione a imagem de capa para a especialidade
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="select-image" className="required">
              Banner
            </label>
            <CreationFormImageGroup>
              {specialty?.images?.banner ? (
                <ThumbnailContainer
                  src={specialty?.images?.banner}
                  removeThumb={removeBanner}
                />
              ) : (
                <Button type="button" id="select-image" onClick={cutBanner}>
                  Selecione o banner para a especialidade
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="specialties" className="required">
              Temas
            </label>

            <CreationFormImageGroup>
              <Button type="button" onClick={selectThemes}>
                Selecionar temas
              </Button>

              {selectedThemes?.length ? (
                <SelectedThemes>
                  {selectedThemes.map((theme) => (
                    <ThemeCard
                      key={theme.content_id}
                      theme={theme}
                      handlePositionChange={handleChangePosition}
                      isFirst={isFirstTheme(theme)}
                      isLast={isLastTheme(theme)}
                    />
                  ))}
                </SelectedThemes>
              ) : (
                <></>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormButtonGroup>
            <Button danger type="button" onClick={goToSpecialties}>
              Cancelar
            </Button>
            <Button primary>Salvar especialidade</Button>
          </CreationFormButtonGroup>
        </CreationForm>
      </TitleAndSpecialtyContainer>
    </CreateAndEditSpecialtiesContainer>
  );
};

export default CreateAndEditSpecialties;
