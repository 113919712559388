import styled, { css } from 'styled-components';

interface PageTitleProps {
  dark?: boolean;
  medium?: boolean;
}

const PageTitle = styled.h2<PageTitleProps>`
  color: var(--white);
  font-weight: 600;

  ${(props) =>
    props.dark &&
    css`
      color: var(--text-black);
    `}
  ${(props) =>
    props.medium &&
    css`
      font-size: 1.1em;
    `}
`;

export default PageTitle;
