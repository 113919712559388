import httpClient from 'src/http-client';

const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  
  return (
    await httpClient.post<{ reference: string; original_name: string }>(
      '/files',
      formData
    )
  ).data;
};

export { uploadFile };
