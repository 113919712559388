import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import Table from 'src/components/AppLayout/components/Table';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import TableAndTitleContainer from 'src/components/AppLayout/components/TableAndTitleContainer';
import { UsersContainer } from './style';
import home from 'src/assets/home.svg';
import User from 'src/models/user';
import makeQuestion from 'src/helpers/make-question';
import showErrorMessage from 'src/helpers/show-error-message';
import showSuccessMessage from 'src/helpers/show-success-message';
import SystemError from 'src/models/error';
import {
  getUsers as getUsersService,
  toggleUserAdmin as toggleUserAdminService,
} from 'src/services/users';
import clamp from 'clamp-js';
import useLoading from 'src/hooks/useLoading';
import ReactSwitch from 'react-switch';

const Users: React.FC = () => {
  const [users, setUsers] = useState([] as User[]);
  const selectedUsersContainer = useRef<HTMLSpanElement>(null);
  const { setIsLoading } = useLoading();

  const getUsers = useCallback(async () => {
    setIsLoading(true);

    const users = await getUsersService();
    setUsers(users);

    setIsLoading(false);
  }, [setIsLoading]);

  const toggleUserAdmin = useCallback(
    async (userId: string, isAdmin: boolean) => {
      const result = await makeQuestion(
        `Deseja ${
          isAdmin ? 'tomar' : 'dar'
        } os privilégios de administrador para este usuário?`
      );

      if (result.isConfirmed) {
        try {
          const shouldAdmin = !isAdmin;
          await toggleUserAdminService(userId);

          showSuccessMessage(
            `Privilégios ${shouldAdmin ? 'dados' : 'tomados'} com sucesso`
          );

          await getUsers();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getUsers]
  );

  const usersToShow = useMemo(() => {
    return (users || []).map((user) => ({
      ...user,
      admin: (
        <ReactSwitch
          checked={user.is_admin}
          onChange={() => toggleUserAdmin(user.user_id, user.is_admin)}
        />
      ),
    }));
  }, [users, toggleUserAdmin]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (selectedUsersContainer.current) {
      clamp(selectedUsersContainer.current, { clamp: 1 });
    }
  }, [selectedUsersContainer]);

  return (
    <UsersContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Usuários</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Outros</strong>,
            <span>Usuários</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <TableAndTitleContainer>
        <PageTitle dark medium>
          Usuários
        </PageTitle>
        <Table
          headerConfigs={[
            {
              label: 'Nome',
              propName: 'name',
              attributes: { style: { width: '240px' } },
            },
            {
              label: 'Email',
              propName: 'email',
            },
            {
              label: 'Admin',
              propName: 'admin',
              attributes: { style: { width: '160px' } },
            },
          ]}
          items={usersToShow}
        />
      </TableAndTitleContainer>
    </UsersContainer>
  );
};

export default Users;
