import React, { useContext } from 'react';
import SearchContext from 'src/contexts/SearchContext';
import {
  HeaderSearchContainer,
  SearchInput,
  SearchResultsContainer,
} from './style';
import { BsSearch } from 'react-icons/bs';
import { useHistory } from 'react-router';

const SearchResults: React.FC = () => {
  return <SearchResultsContainer></SearchResultsContainer>;
};

const HeaderSearch: React.FC = () => {
  const { search, setSearch } = useContext(SearchContext);
  const history = useHistory();

  return (
    <HeaderSearchContainer
      onSubmit={(e) => {
        e.preventDefault();
        if (search.trim()) history.push(`/search/${search}`);
      }}
    >
      <SearchInput
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Pesquisar..."
        icon={<BsSearch size={16} />}
      />
      <SearchResults />
    </HeaderSearchContainer>
  );
};

export default HeaderSearch;
