import styled from 'styled-components';

const FeatureList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  justify-items: center;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default FeatureList;
