import SystemError from 'src/models/error';
import Swal from 'sweetalert2';

const showErrorMessage = (error: SystemError) => {
  let errorMessage = error.message;
  if (error.response?.data) {
    if (error.response.data?.validation?.body?.message) {
      errorMessage = error.response.data.validation.body.message;
    } else if (error.response?.data?.message) {
      errorMessage = error.response.data.message;
    }
  }

  Swal.fire({ icon: 'error', text: errorMessage });
};

export default showErrorMessage;
