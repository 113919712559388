import axios from 'axios';
import SignInCredentials from 'src/models/sign-in-credentials';
import urlJoin from 'url-join';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const login = async ({ email, password }: SignInCredentials) => {
  return (
    await axios.post(urlJoin(baseUrl!, '/sessions/login'), { email, password })
  ).data;
};

export { login };
