import React, { cloneElement, useContext, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import ModalContext from 'src/contexts/ModalContext';
import styled from 'styled-components';
import Button from './Button';
import { v4 as uuidv4 } from 'uuid';

const ModalContentContainer = styled.div`
  padding: 16px;
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 9;
  box-shadow: 0 0 5px 1px var(--black-60);
`;

const ModalTitle = styled.h2`
  font-size: 1rem;
  margin-bottom: 16px;
`;

const ModalCloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  padding: 16px;

  :hover {
    box-shadow: none;
  }
`;

const ModalContent = styled.div``;

interface ModalProps {
  title: string;
  modalId: string;
}

const Modal: React.FC<ModalProps> = ({ children, title, modalId }) => {
  const { hideModal } = useContext(ModalContext);

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <ModalContentContainer onClick={stopPropagation}>
      <ModalTitle>{title}</ModalTitle>
      <ModalCloseButton type="button" onClick={() => hideModal(modalId)}>
        <AiOutlineClose />
      </ModalCloseButton>

      <ModalContent>{children}</ModalContent>
    </ModalContentContainer>
  );
};

const ModalContextProvider: React.FC = ({ children }) => {
  const [modals, setModals] = useState([] as JSX.Element[]);

  const showModal = (title: string, content: JSX.Element) => {
    const modalId = uuidv4();

    const clonedContent = cloneElement(content, { modalId });

    setModals((modals) => [
      ...modals,
      <Modal key={modalId} title={title} modalId={modalId}>
        {clonedContent}
      </Modal>,
    ]);
  };

  const hideModal = (modalId?: string) => {
    if (modalId) {
      setModals((modals) => modals.filter((modal) => modal.key !== modalId));
    } else {
      setModals([]);
    }
  };

  return (
    <ModalContext.Provider value={{ modals, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
