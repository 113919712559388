import styled from 'styled-components';

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--white);

  strong {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: var(--white);

    :hover {
      text-decoration: underline;
    }
  }
`;

export { BreadcrumbContainer };
