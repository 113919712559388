import Button from 'src/components/Button';
import styled, { css } from 'styled-components';

interface AppLayoutSidebarContainerProps {
  opened: boolean;
}

const AppLayoutSidebarContainer = styled.div<AppLayoutSidebarContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background: var(--white);
  height: 100vh;
  width: 240px;
  padding: 16px;
  overflow-y: auto;

  ${(props) =>
    !props.opened &&
    css`
      width: 80px;
    `}
`;

const LogoAndToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 24px;
`;

const CustomerLogo = styled.img``;

const SidebarToggleButton = styled(Button)`
  color: var(--gray);
  padding: 8px;
`;

export {
  AppLayoutSidebarContainer,
  LogoAndToggleContainer,
  CustomerLogo,
  SidebarToggleButton,
};
