import React, { useContext } from 'react';
import ModalContext from 'src/contexts/ModalContext';
import { ModalsContainer } from './style';

const Modals: React.FC = () => {
  const { modals, hideModal } = useContext(ModalContext);

  return modals?.length ? (
    <ModalsContainer onClick={() => hideModal()}>{modals}</ModalsContainer>
  ) : (
    <></>
  );
};

export default Modals;
