import { createContext, Dispatch, SetStateAction } from 'react';
import Theme from 'src/models/theme';

interface ISpecialtyCreationContext {
  selectedThemes: Theme[];
  setSelectedThemes: Dispatch<SetStateAction<Theme[]>>;
}

const SpecialtyCreationContext = createContext({} as ISpecialtyCreationContext);

export default SpecialtyCreationContext;
