import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import AppContext from 'src/contexts/AppContext';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import FeatureCard from 'src/components/AppLayout/components/FeatureCard';
import home from 'src/assets/home.svg';
import Button from 'src/components/Button';
import { SearchResultsContainer, ResultsList } from './style';

interface ISearchResults {
  title: string;
  description: string;
  path: string;
  tags: string;
}

const possibleResults: ISearchResults[] = [
  {
    title: "Especialidades",
    description: "Liste as especialidades existentes, crie ou edite uma nova agora!",
    path: "/customization/academic/specialties",
    tags: "especialidades acadêmico academico"
  },
  {
    title: "Temas",
    description: "Liste os temas existentes, crie ou edite um novo agora!",
    path: "/customization/academic/themes",
    tags: "temas acadêmico academico"
  },
  {
    title: "Conteúdos",
    description: "Liste os conteúdos existentes, crie ou edite um novo agora!",
    path: "/customization/academic/contents",
    tags: "conteúdos conteudos acadêmico academico"
  },
  {
    title: "Autores",
    description: "Liste os autores existentes, crie ou edite um novo agora!",
    path: "/customization/academic/authors",
    tags: "autores acadêmico academico"
  },
  {
    title: "Engajamento",
    description: "Acesse os relatórios de engajamento.",
    path: "/others/reports/engagement",
    tags: "engajamento relatórios relatorios"
  },
  {
    title: "Conteúdos assistidos",
    description: "Acesse os relatórios de conteúdos assistidos.",
    path: "/others/reports/contents-finished",
    tags: "conteúdos assistidos conteudos assistidos relatórios relatorios"
  },
];

const SearchResults: React.FC = () => {
  const { query } = useParams<{ query: string }>();
  const [results, setResults] = useState<ISearchResults[]>([]);
  const { selectedPartner } = useContext(AppContext);
  const history = useHistory();

  const goTo = (address: string) => {
    history.push(address);
  };

  const filterResults = useCallback((searchQuery: string): void => {
    const resultsToFilter = selectedPartner ? possibleResults : [
      {
        title: "Parceiros",
        description: "Liste os parceiros existentes, crie ou edite um novo agora!",
        path: "/customization/partners",
        tags: "parceiros"
      },
      ...possibleResults
    ];

    const filteredData = resultsToFilter.filter(item => item.tags.includes(searchQuery.toLowerCase()));
    setResults(filteredData);
  }, []);

  useEffect(() => {
    filterResults(query);
  }, [query]);

  return (
    <SearchResultsContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Resultados</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Pesquisa</strong>
          ]}
        />
      </TitleAndBreadcrumbContainer>

      {results.length ? (
        <ResultsList>
          {results.map((result) => {
            return (
              <FeatureCard>
                <span className="title">{result.title}</span>

                <span className="description">{result.description}</span>

                <Button
                  type="button"
                  className="view-more"
                  onClick={() => goTo(result.path)}
                >
                  Ver mais
                </Button>
              </FeatureCard>
            )
          })}
        </ResultsList>
      ) : (
        <span className="no-results">Não há resultados para a sua busca.</span>
      )}
    </SearchResultsContainer>
  );
};

export default SearchResults;
