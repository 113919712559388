import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/components/AppLayout/components/Breadcrumb';
import PageTitle from 'src/components/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/components/AppLayout/components/TitleAndBreadcrumbContainer';
import Button from 'src/components/Button';
import { PartnersContainer } from './style';
import home from 'src/assets/home.svg';
import CreateAndSearchContainer from 'src/components/AppLayout/components/CreateAndSearchContainer';
import TableAndTitleContainer from 'src/components/AppLayout/components/TableAndTitleContainer';
import Table from 'src/components/AppLayout/components/Table';
import useLoading from 'src/hooks/useLoading';
import makeQuestion from 'src/helpers/make-question';
import showSuccessMessage from 'src/helpers/show-success-message';
import showErrorMessage from 'src/helpers/show-error-message';
import SystemError from 'src/models/error';
import Partner from 'src/models/partner';
import ActionButtonsContainer from 'src/components/AppLayout/components/ActionButtonsContainer';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import { MdOutlineManageAccounts } from 'react-icons/md';
import {
  getPartners as getPartnersService,
  deletePartner as deletePartnerService,
} from 'src/services/partners';
import AppContext from 'src/contexts/AppContext';

const Partners: React.FC = () => {
  const history = useHistory();
  const [partners, setPartners] = useState([] as Partner[]);
  const { setIsLoading } = useLoading();
  const { setSelectedPartner } = useContext(AppContext);

  const getPartners = useCallback(async () => {
    setIsLoading(true);

    const partners = await getPartnersService();
    setPartners(partners);

    setIsLoading(false);
  }, [setIsLoading]);

  const createPartner = () => {
    history.push(`/customization/partners/create-partner`);
  };

  const editPartner = useCallback(
    (partnerId: string) => {
      history.push(`/customization/partners/edit-partner/${partnerId}`);
    },
    [history]
  );

  const removePartner = useCallback(
    async (partnerId: string) => {
      const result = await makeQuestion('Deseja excluir este parceiro?');

      if (result.isConfirmed) {
        try {
          await deletePartnerService(partnerId);

          showSuccessMessage('Parceiro removido com sucesso');

          await getPartners();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getPartners]
  );

  const handleChangePartner = useCallback(
    (partner: Partner) => {
      setSelectedPartner(partner);
      history.push('/home');
    },
    [history, setSelectedPartner]
  );

  const partnersToShow = useMemo(() => {
    return (partners || []).map((partner) => ({
      ...partner,
      url: (
        <a
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_FRONT_BASE_URL}/${partner.url_complement}`}
        >{`${process.env.REACT_APP_FRONT_BASE_URL}/${partner.url_complement}`}</a>
      ),
      actions: (
        <ActionButtonsContainer>
          <Button
            primary
            type="button"
            onClick={() => editPartner(partner.partner_id)}
          >
            <AiOutlineEdit />
            <span>Editar</span>
          </Button>
          <Button
            warning
            type="button"
            onClick={() => handleChangePartner(partner)}
          >
            <MdOutlineManageAccounts />
            <span>Administrar</span>
          </Button>
          <Button
            dangerFill
            type="button"
            onClick={() => removePartner(partner.partner_id)}
          >
            <BsTrashFill />
            <span>Excluir</span>
          </Button>
        </ActionButtonsContainer>
      ),
    }));
  }, [partners, editPartner, handleChangePartner, removePartner]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  return (
    <PartnersContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Parceiros</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Personalização</strong>,
            <span>Parceiros</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <CreateAndSearchContainer>
        <Button type="button" success onClick={createPartner}>
          Criar novo parceiro
        </Button>
      </CreateAndSearchContainer>

      <TableAndTitleContainer>
        <PageTitle dark medium>
          Parceiros
        </PageTitle>
        <Table
          headerConfigs={[
            {
              label: 'Nome',
              propName: 'name',
            },
            {
              label: 'URL',
              propName: 'url',
            },
            {
              label: 'Ações',
              propName: 'actions',
              attributes: { style: { width: '280px' } },
            },
          ]}
          items={partnersToShow}
        />
      </TableAndTitleContainer>
    </PartnersContainer>
  );
};

export default Partners;
