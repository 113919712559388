import Input from 'src/components/Input';
import styled from 'styled-components';

const HeaderSearchContainer = styled.form`
  position: relative;
`;

const SearchResultsContainer = styled.div``;

const SearchInput = styled(Input)`
  background: white;
  border-radius: 24px;
  padding-left: 8px;

  input {
    padding: 16px 0;
  }
`;

export { HeaderSearchContainer, SearchResultsContainer, SearchInput };
