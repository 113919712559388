import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SignInCredentials from '../models/sign-in-credentials';
import User from '../models/user';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from 'src/contexts/AuthContext';
import { login as loginService } from 'src/services/login';
import { getUser as getUserService } from 'src/services/users';
import Swal from 'sweetalert2';

const AuthContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [user, setUser] = useState<User | undefined>();

  const signIn = async (signInCredentials: SignInCredentials) => {
    const { token } = await loginService(signInCredentials);
    window.localStorage.setItem('manole-api-token', token);
  };

  const signOut = () => {
    window.localStorage.removeItem('manole-api-token');
    history.push('/');
  };

  const isOnLoginPage = useMemo(() => {
    return location.pathname === '/';
  }, [location.pathname]);

  const getUser = useCallback(async () => {
    if (!isOnLoginPage) {
      try {
        const user = await getUserService();
        setUser(user);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'Você está deslogado. Faça login para continuar!',
        });
        history.push('/');
      }
    }
  }, [history, isOnLoginPage]);

  const isAuthenticated = useMemo(() => !!user, [user]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn, signOut }}>
      {isAuthenticated || isOnLoginPage ? children : <></>}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
