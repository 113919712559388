import Swal from 'sweetalert2';

const makeQuestion = async (question: string) => {
  return await Swal.fire({
    icon: 'question',
    text: question,
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
  });
};

export default makeQuestion;
