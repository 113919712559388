import styled from 'styled-components';

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  button {
    min-width: 100px !important;
    max-width: 140px !important;
  }
`;

export default ActionButtonsContainer;
