import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ThemeCreationContext from 'src/contexts/ThemeCreationContext';
import Content from 'src/models/content';

const ThemeCreationContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [selectedContents, setSelectedContents] = useState([] as Content[]);

  useEffect(() => {
    setSelectedContents([]);
  }, [location.pathname]);

  return (
    <ThemeCreationContext.Provider
      value={{ selectedContents, setSelectedContents }}
    >
      {children}
    </ThemeCreationContext.Provider>
  );
};

export default ThemeCreationContextProvider;
