import Button from 'src/components/Button';
import styled from 'styled-components';

const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 440px;
  margin: 0 auto;
`;

const LoginForm = styled.form`
  border-radius: 4px;
  width: 100%;
  height: 300px;
  background-color: var(--white);
  box-shadow: 0px 0px 3px 0px #ffffff;
  padding: 32px;

  & .remember {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding-left: 8px;
    color: var(--gray);
  }

  & .enter {
    color: var(--gray);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
`;

const LoginUpperText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
  width: 100%;

  h3 {
    margin-bottom: 16px;
    font-size: 1.8rem;
  }

  p {
    font-size: 1.2rem;
    font-weight: 300;
  }

  p,
  h3 {
    color: var(--white);
    text-align: center;
  }
`;

const LoginLowerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;

  a {
    color: var(--white);
    font-size: 0.85em;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

const LoginSubmit = styled(Button)`
  background-color: var(--primary);
  color: var(--white);
  font-weight: bold;
  padding: 12px 16px;

  :hover {
    box-shadow: 0px 0px 10px 0px var(--primary);
  }
`;

export {
  LoginContainer,
  LoginForm,
  LoginUpperText,
  LoginLowerContent,
  LoginSubmit,
};
