import React, { useState } from 'react';
import SearchContext from 'src/contexts/SearchContext';

const SearchContextProvider: React.FC = ({ children }) => {
  const [search, setSearch] = useState('');

  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
