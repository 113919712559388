import httpClient from 'src/http-client';
import Author from 'src/models/author';

const getAuthors = async (filters?: any) => {
  return (
    await httpClient.get<Author[]>(`/authors`, {
      params: { ...filters },
    })
  ).data;
};

const getAuthor = async (authorId: string) => {
  return (await httpClient.get<Author>(`/authors/${authorId}`)).data;
};

const createAuthor = async (newAuthor: Partial<Author>) => {
  return (await httpClient.post(`/authors`, { ...newAuthor })).data;
};

const updateAuthor = async (authorId: string, newAuthor: Partial<Author>) => {
  delete newAuthor.author_id;
  delete newAuthor.created_at;
  delete newAuthor.updated_at;

  return (
    await httpClient.put(`/authors/${authorId}`, {
      ...newAuthor,
    })
  ).data;
};

const deleteAuthor = async (authorId: string) => {
  await httpClient.delete(`/authors/${authorId}`);
};

const toggleAuthorActive = async (authorId: string, shouldActive: boolean) => {
  return (
    await httpClient.patch(`/authors/${authorId}/active`, {
      is_active: shouldActive,
    })
  ).data;
};

const toggleContentHighlight = async (authorId: string) => {
  const content = await getAuthor(authorId);

  if (content.info) {
    content.info.is_highlight = !content.info.is_highlight;
  } else {
    content.info = { is_highlight: true };
  }

  return updateAuthor(authorId, content);
};

export {
  getAuthors,
  getAuthor,
  createAuthor,
  updateAuthor,
  deleteAuthor,
  toggleAuthorActive,
  toggleContentHighlight,
};
