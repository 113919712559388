import styled, { css } from 'styled-components';

const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: var(--white);
  box-shadow: 0 0 3px 1px var(--black-60);
  border-radius: 4px;
  padding: 16px;
`;

interface FilterFormGroupProps {
  columns?: number;
}

const FilterFormGroup = styled.div<FilterFormGroupProps>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${(props) =>
    props.columns &&
    css`
      grid-template-columns: repeat(${props.columns}, 1fr);
    `}
`;

const FilterFormInputGroup = styled.span``;

const FilterFormButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export {
  FilterForm,
  FilterFormGroup,
  FilterFormInputGroup,
  FilterFormButtonGroup,
};
