import React, { useState } from 'react';
import SidebarContext from '../contexts/SidebarContext';

const SidebarContextProvider: React.FC = ({ children }) => {
  const [opened, setOpened] = useState(true);

  return (
    <SidebarContext.Provider value={{ opened, setOpened }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContextProvider;
