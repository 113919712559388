import httpClient from 'src/http-client';
import Partner from 'src/models/partner';

const getPartners = async (filters?: any) => {
  return (
    await httpClient.get<Partner[]>(`/partners`, {
      params: { ...filters },
    })
  ).data;
};

const getPartner = async (partnerId: string) => {
  return (
    await httpClient.get<Partner>(`/partners/${partnerId}`, {
      params: { relations: ['children'] },
    })
  ).data;
};

const createPartner = async (newPartner: Partial<Partner>) => {
  return (await httpClient.post(`/partners`, { ...newPartner })).data;
};

const updatePartner = async (
  partnerId: string,
  newPartner: Partial<Partner>
) => {
  delete newPartner.partner_id;
  delete newPartner.created_at;
  delete newPartner.updated_at;
  delete newPartner.segmentation_item_id;

  return (
    await httpClient.put(`/partners/${partnerId}`, {
      ...newPartner,
    })
  ).data;
};

const deletePartner = async (partnerId: string) => {
  await httpClient.delete(`/partners/${partnerId}`);
};

const togglePartnerActive = async (
  partnerId: string,
  shouldActive: boolean
) => {
  return (
    await httpClient.patch(`/partners/${partnerId}/active`, {
      is_active: shouldActive,
    })
  ).data;
};

export {
  getPartners,
  getPartner,
  createPartner,
  updatePartner,
  deletePartner,
  togglePartnerActive,
};
