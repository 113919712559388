import styled from 'styled-components';

const AppLayoutHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 80px;
  width: 100%;
  padding: 8px 24px;
`;

export { AppLayoutHeaderContainer };
