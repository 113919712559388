import styled from 'styled-components';

const FeatureCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;

  width: 360px;
  height: 240px;
  background: var(--white);
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 1px #8898aab4;

  & .title {
    font-size: 1.1rem;
    font-weight: 600;
  }

  & .description {
    flex: 1;
    font-size: 1rem;
    color: var(--black-60);
  }

  & .view-more {
    background: var(--primary);
    color: var(--white);
    padding: 8px 24px;

    :hover {
      box-shadow: 0px 0px 5px 1px var(--primary);
    }
  }
`;

export { FeatureCardContainer };
