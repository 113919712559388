import styled from 'styled-components';

const SelectOrUploadMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  width: 80vw;
  min-height: 65vh;
`;

const MediaFilters = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MediaList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

const ProcessingMediaCard = styled.div`
  display: flex;
  gap: 8px;
  border-top: solid 1px var(--border-gray);
  border-bottom: solid 1px var(--border-gray);

  & .title,
  & .status {
    padding: 8px;
  }

  & .title {
    border-left: solid 1px var(--border-gray);
    flex: 1;
  }

  & .status {
    border-left: solid 1px var(--border-gray);
  }
`;

export {
  SelectOrUploadMediaContainer,
  MediaFilters,
  MediaList,
  ProcessingMediaCard,
};
