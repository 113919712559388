import React from 'react';
import { InputContainer } from './style';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  icon?: JSX.Element;
}

const Input: React.FC<InputProps> = ({ icon, className, ...rest }) => {
  return (
    <InputContainer className={className}>
      {icon && <span className="icon">{icon}</span>}
      <input {...rest} />
    </InputContainer>
  );
};

export default Input;
