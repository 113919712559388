import { createContext } from 'react';

interface IModalContext {
  modals: JSX.Element[];
  showModal: (title: string, content: JSX.Element) => void;
  hideModal: (modalId?: string) => void;
}

const ModalContext = createContext({} as IModalContext);

export default ModalContext;
