import React from 'react';
import { AppLayoutFooterContainer } from './style';

const AppLayoutFooter: React.FC = () => {
  return (
    <AppLayoutFooterContainer>
      <span className="copy">&copy; 2021 Samba Digital</span>
    </AppLayoutFooterContainer>
  );
};

export default AppLayoutFooter;
