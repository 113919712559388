import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Button from 'src/components/Button';
import styled from 'styled-components';

const VidContainer = styled.div`
  position: relative;
  width: 640px;
  height: 360px;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const RemoveButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(35%, -35%);
  max-width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 16px;
  background: var(--danger);
  color: var(--white);
`;

interface VideoContainerProps {
  reference: string;
  removeVideo: () => void;
}

const VideoContainer: React.FC<VideoContainerProps> = ({
  reference,
  removeVideo,
}) => {
  return (
    <VidContainer>
      <iframe src={reference} frameBorder={0} title="video"></iframe>
      <RemoveButton type="button" onClick={removeVideo} danger>
        <AiOutlineClose />
      </RemoveButton>
    </VidContainer>
  );
};

export default VideoContainer;
